import React, { useCallback, useState } from 'react'

import { AssetContextType, IDate, UUID } from '@avantstay/arriere-clients/dist/arriereBackoffice'
import { getNewUuid } from '@avantstay/backoffice-core'

export interface AssetWithDocumentProps {
  id: UUID
  context: AssetContextType
  s3Bucket: string
  s3Key: string
  public: boolean
  targetId?: UUID
  createdAt: IDate
  updatedAt?: IDate
  extension?: string
  size?: number
  fileName?: string
  url: string
  documentId?: UUID
  description?: string
}

export interface FileWithError {
  [id: string]: File | undefined
}

export interface UseManageAssetsStateProps {
  files: AssetWithDocumentProps[]
  filesUploadProgress: { [id: string]: number } | {}
  filesWithError: FileWithError
}

function useManageAssets(
  targetId: string,
  files: AssetWithDocumentProps[],
  createAsset: (
    targetId: string,
    file: File,
    fileId: string,
    progressFileFn: (id: string) => (progress: number) => void,
  ) => Promise<any>,
) {
  const [state, setState] = useState<UseManageAssetsStateProps>({
    files,
    filesUploadProgress: {},
    filesWithError: {},
  })

  const setFileUploadProgress = (fileId: string) => (progress: number) => {
    setState(prev => ({
      ...prev,
      filesUploadProgress: {
        ...prev.filesUploadProgress,
        [fileId]: progress,
      },
    }))
  }

  const saveFilesUri = useCallback(
    async (newFiles: Array<File>) => {
      const filesDetailed = newFiles.map(it => {
        return {
          id: getNewUuid(),
          size: it.size,
          url: URL.createObjectURL(it),
          fileName: it.name,
          createdAt: new Date(),
          documentId: getNewUuid(),
        }
      }) as AssetWithDocumentProps[]

      setState(prev => ({
        ...prev,
        files: [...prev.files, ...filesDetailed],
      }))

      const filesUploadedPromise = filesDetailed.map(async (it, key) => {
        const result = await createAsset(targetId, newFiles[key], it.id, setFileUploadProgress)

        setState(prev => {
          const currentFiles = Array.from(prev.files)
          const fileIndex = currentFiles.findIndex(file => file.id === it.id)
          const newFileIndex = fileIndex !== -1 ? fileIndex : currentFiles.length
          const updatedFiles = currentFiles.map((file, index) => {
            if (index === newFileIndex) {
              return {
                ...currentFiles[newFileIndex],
                url: result?.localUrl,
                id: result?.assetId,
                documentId: result?.documentId,
                description: result?.description,
              }
            }

            return file
          })

          return {
            ...prev,
            files: updatedFiles,
          }
        })

        return {
          url: result?.localUrl,
          id: result?.assetId,
          documentId: result?.documentId,
          description: result?.description,
        }
      })

      await Promise.all(filesUploadedPromise).catch(() => {
        setState(prev => {
          const formattedFilesWithErrors = filesDetailed.reduce((accFiles, currentFile) => {
            const originalFile = newFiles.find(file => file.name === currentFile.fileName)
            return {
              ...accFiles,
              [currentFile.id]: originalFile,
            }
          }, {})

          return {
            ...prev,
            filesWithError: { ...prev.filesWithError, ...formattedFilesWithErrors },
          }
        })
      })
    },
    [targetId, createAsset],
  )

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      saveFilesUri(Array.from(e.target.files || []))
    },
    [saveFilesUri],
  )

  const handleDrop = useCallback(
    (e: React.DragEvent<HTMLLabelElement>) => {
      e.preventDefault()
      saveFilesUri(Array.from(e.dataTransfer.files))
    },
    [saveFilesUri],
  )

  const handleRetryFile = useCallback(
    (fileToUpload: File) => {
      const filesWithError = { ...state.filesWithError }
      const updatedFilesWithError = Object.keys(filesWithError).reduce((accFiles, fileKey) => {
        const currentFile = filesWithError[fileKey]

        if (currentFile?.name === fileToUpload.name) {
          setFileUploadProgress(fileKey)
          return accFiles
        }

        return { ...accFiles, [fileKey]: currentFile }
      }, {})

      setState(prev => ({
        ...prev,
        files: prev.files.filter(file => file.fileName !== fileToUpload.name),
        filesWithError: updatedFilesWithError,
      }))

      saveFilesUri([fileToUpload])
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [saveFilesUri],
  )

  const sortFiles = useCallback(
    (sourceIndex: number, destinationIndex?: number) => {
      if (typeof destinationIndex !== 'number') return

      const items = Array.from(state.files)

      const [removed] = items.splice(sourceIndex, 1)
      items.splice(destinationIndex, 0, removed)

      setState(prev => ({ ...prev, files: items }))
    },
    [state.files],
  )

  const setFiles = useCallback((newFiles: AssetWithDocumentProps[]) => {
    setState(prev => ({ ...prev, newFiles }))
  }, [])

  const removeFile = (index: number) => {
    const localFiles = [...state.files]
    const filesWithError = { ...state.filesWithError }

    const fileToRemove = localFiles.filter((_, key) => key === index)[0]
    const updatedFilesWithError = Object.keys(filesWithError).reduce((accFiles, fileKey) => {
      const currentFile = filesWithError[fileKey]

      if (currentFile?.name === fileToRemove.fileName) {
        setFileUploadProgress(fileKey)
        return accFiles
      }

      return { ...accFiles, currentFile }
    }, {})

    setState(prev => ({
      ...prev,
      files: prev.files.filter((_, key) => key !== index),
      filesWithError: updatedFilesWithError,
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }

  return {
    handleInputChange,
    handleDrop,
    handleRetryFile,
    sortFiles,
    setFiles,
    removeFile,
    ...state,
  }
}

export default useManageAssets
