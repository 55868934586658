import styled, { css } from 'styled-components'

import { _M_Normal, _XS_Normal, mainColors, semanticColors } from '../../'
import { RadioFieldLabelProps } from './_types'

export const RadioSelector = css`
  display: block;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  position: absolute;
  left: 3px;
  top: 3px;
  background: ${mainColors.white};
`

export const GroupContainer = styled.div``

export const Container = styled.div<{ hasSubtitle?: boolean }>`
  position: relative;
  height: 100%;
  margin: ${props => (props.hasSubtitle ? '5px 0 10px' : '5px 0')};
`

export const TitleColumn = styled.div``

const textCommonStyles = css`
  margin-left: 11px;
  min-width: max-content;
`

export const Title = styled.div`
  ${_M_Normal};
  color: ${mainColors.neutralExtremelyDark};
  ${textCommonStyles}
`

export const Subtitle = styled.div`
  ${_XS_Normal};
  margin-top: 4px;
  color: ${mainColors.neutralExtraDark};
  margin-left: 11px;
`

export const Checkbox = styled.div<{ error?: boolean }>`
  min-width: 18px;
  min-height: 18px;
  border: 2px solid
    ${({ error }) => (error ? semanticColors.failureMedium : mainColors.neutralDark)};
  border-radius: 50%;
  position: relative;
`

export const RadioLabel = styled.label<RadioFieldLabelProps>`
  display: flex;
  align-items: flex-start;
  background: transparent;
  cursor: pointer;
  display: flex;
`

export const RadioHiddenInput = styled.input<RadioFieldLabelProps>`
  position: absolute;
  opacity: 0;
  z-index: 1;

  &:hover + ${RadioLabel} {
    ${Checkbox} {
      border-color: ${({ error }) =>
        error ? semanticColors.failureDark : mainColors.neutralExtraDark};
    }
  }

  &:checked + ${RadioLabel} {
    ${Checkbox} {
      background: ${mainColors.neutralExtraDark};
      border-color: ${mainColors.neutralExtraDark};

      &::after {
        content: '';
        ${RadioSelector}
      }
    }

    ${Title} {
      color: ${mainColors.black};
    }

    ${Subtitle} {
      color: ${mainColors.neutralExtremelyDark};
    }
  }

  &:disabled + ${RadioLabel} {
    border-color: ${mainColors.neutralLight};
    cursor: not-allowed;
    pointer-events: none;

    ${Checkbox} {
      &::after {
        content: '';
        ${RadioSelector};
        background: ${mainColors.neutralDark};
      }
    }

    ${Title} {
      color: ${mainColors.neutralExtraDark};
    }
  }
`

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`

export const IconContainer = styled.div`
  padding-left: 8px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`
