import { LocalTime } from '@avantstay/arriere-clients/dist/arriereBackoffice'

import { HomeFieldHistory } from '@new/domains/properties/screens/propertyInfo/BasicInfo/BasicInfo.types'

import { HomeVerificationDataInput, IUpdateHomeParams } from '@arriere/types/Properties.types'

export enum PropertyInfoSections {
  basicInfo = 'basic-info',
  leasing = 'leasing',
}

export type BasicField = {
  key: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any
  isVerified?: boolean
  history?: HomeFieldHistory
}

export type BasicFieldsView = {
  [key in keyof typeof PropertyInfoFields]: BasicField
}

export interface PropertyInfoFormProps extends BasicFieldsView {}

export interface SubmitBasicInfoProps extends Omit<IUpdateHomeParams, 'customFields' | 'homeId'> {
  checkOutTime?: LocalTime
  squareFootage?: string
  homeAncestors?: string[]
  verifications?: HomeVerificationDataInput[]
}

export enum PropertyInfoFields {
  name = 'NAME',
  title = 'TITLE',
  bathroomsCount = 'BATHROOMS_COUNT',
  checkOutTime = 'CHECKOUT_TIME',
  halfBathroomsCount = 'HALF_BATHROOMS_COUNT',
  maxOccupancy = 'MAX_OCCUPANCY',
  maxAdults = 'MAX_ADULTS',
  address = 'ADDRESS',
  city = 'CITY',
  zipCode = 'ZIP_CODE',
  squareFootage = 'SQUARE_FOOTAGE',
  homeRegion = 'REGION_ID',
  shortDescription = 'SHORT_DESCRIPTION',
  description = 'DESCRIPTION',
  homeAncestors = 'BUYOUT',
}

export enum PropertyCustomFieldSections {
  generalConfiguration = 'GENERAL_CONFIGURATION',
  documents = 'DOCUMENTS',
  marketing = 'MARKETING',
  propertyMapping = 'PROPERTY_MAPPING',
  leaseAndFinance = 'LEASE_AND_FINANCE',
  ownerAndLighthouse = 'OWNER_AND_LIGHTHOUSE',
  directAndOtherSettings = 'DIRECT_AND_OTHER_SETTINGS',
}
