import type {
  LeadSource,
  RequestStatus,
  UUID,
} from '@avantstay/arriere-clients/dist/arriereBackoffice'
import arriereBackoffice, { RefundType } from '@avantstay/arriere-clients/dist/arriereBackoffice'

import fetchApi from '@/utils/fetchApi'
import type {
  AdjustmentPossibilityProps,
  ApproveBookingRequestsProps,
  CancelAdjustmentProps,
  ProcessRequestProps,
  RecommendedAmountProps,
  RefundPaymentSourceProps,
  RejectRequestProps,
  RequestAdjustmentProps,
} from '@arriere/types/BookingAdjustment.types'
import { UpdateAdjustmentRequestProps } from '@arriere/types/BookingAdjustment.types'
import REFUND_REQUESTS_QUERY from '@queries/exportCsv/refundRequestsCSV.graphql'

const refundPaymentSourceInputFragment = {
  affirm: true,
  bankLast4: true,
  cardLast4: true,
  credits: true,
  manual: true,
  platform: true,
  processType: true,
  paymentMethodId: true,
}

// Queries
export const getBookingAdjustmentReasons = () => {
  return arriereBackoffice.queries.adjustmentRequests({
    requestReasons: {
      id: true,
      name: true,
      group: {
        id: true,
        name: true,
        category: {
          id: true,
          name: true,
        },
      },
    },
  })
}

export const getBookingAdjustmentRecommendedBaseAmount = (bookingId: string, reasonId: number) => {
  return arriereBackoffice.queries.adjustmentRequests({
    recommendedBaseAmount: {
      __args: { bookingId, reasonId },
      reasonId: true,
      baseAmount: true,
      calculatedAmount: true,
      maxQuantity: true,
      basis: true,
      refundType: true,
    },
  })
}

export const getBookingAdjustmentRecommendedTotalAmount = async ({
  bookingId,
  reasonId,
  baseAmount,
  quantity,
}: RecommendedAmountProps) => {
  return arriereBackoffice.queries.adjustmentRequests
    .raw({
      recommendedTotalAmount: {
        __args: { bookingId, reasonId, baseAmount, quantity },
        total: true,
        refundType: true,
      },
    })
    .then(({ data, errors }) => {
      if (errors?.length) {
        throw new Error(errors[errors?.length - 1]?.message)
      }

      return {
        amount: data.recommendedTotalAmount.total,
        type: RefundType.money,
      }
    })
}

export const getApprovalRequirementsPreview = async ({
  bookingId,
  inputs,
}: AdjustmentPossibilityProps) => {
  const { data, errors } = await arriereBackoffice.queries.adjustmentRequests.raw({
    __alias: 'getApprovalRequirementsPreview',
    approvalRequirementsPreview: {
      __args: { bookingId, inputs },
      refundType: true,
      refundableAmount: true,
      approvalRequired: {
        totalAmount: true,
        reason: {
          reason: true,
          description: true,
        },
      },
    },
  })
  if (errors?.length) {
    throw new Error(errors[errors?.length - 1]?.message)
  }

  return data.approvalRequirementsPreview
}

export const getAdjustmentRequestsToApprove = async (bookingId: string) => {
  const { data, errors } = await arriereBackoffice.queries.adjustmentRequests.raw({
    __alias: 'getAdjustmentRequestsToApprove',
    requestsToApprove: {
      __args: { bookingId },
      id: true,
      bookingId: true,
      reasonId: true,
      reasonName: true,
      status: true,
      createdAt: true,
      createdBy: {
        name: true,
      },
      refundInitiated: true,
      refundInitiatedAt: true,
      requestedAmount: true,
      refundInitiatedBy: {
        name: true,
      },
      reviewedAt: true,
      reviewedBy: {
        name: true,
      },
      amounts: {
        id: true,
        requested: true,
        approved: true,
        refundType: true,
        refundSource: {
          ...refundPaymentSourceInputFragment,
        },
        refundedAt: true,
      },
      recommended: {
        amount: true,
        refundType: true,
        requestId: true,
      },
      reason: {
        id: true,
        name: true,
        description: true,
        refundType: true,
        calculationBasis: true,
        group: {
          name: true,
        },
      },
      statesHistory: {
        note: true,
        status: true,
      },
    },
  })
  if (errors?.length) {
    throw new Error(errors[errors?.length - 1]?.message)
  }

  return data.requestsToApprove
}

export const getAdjustmentRequestsToProcess = async (bookingId: string) => {
  const { data, errors } = await arriereBackoffice.queries.adjustmentRequests.raw({
    __alias: 'getAdjustmentRequestsToProcess',
    requestsToRefund: {
      __args: { bookingId },
      id: true,
      bookingId: true,
      reasonId: true,
      status: true,
      createdAt: true,
      createdBy: {
        name: true,
      },
      refundInitiated: true,
      refundInitiatedAt: true,
      refundInitiatedBy: {
        name: true,
      },
      reviewedAt: true,
      reviewedBy: {
        name: true,
      },
      amounts: {
        id: true,
        requested: true,
        approved: true,
        refundType: true,
        refundSource: {
          ...refundPaymentSourceInputFragment,
        },
        refundedAt: true,
      },
      recommended: {
        amount: true,
        refundType: true,
        requestId: true,
      },
      reason: {
        id: true,
        name: true,
        description: true,
        refundType: true,
        group: {
          name: true,
        },
      },
      statesHistory: {
        author: {
          name: true,
        },
        note: true,
        status: true,
      },
    },
  })
  if (errors?.length) {
    throw new Error(errors[errors?.length - 1]?.message)
  }
  return data.requestsToRefund
}

export interface GetBookingRequestsProps {
  checkInFrom?: string
  checkInTo?: string
  checkOutFrom?: string
  checkOutTo?: string
  page: number
  regionIds?: UUID[]
  term?: string
  size: number
  sources?: LeadSource[]
  statuses?: RequestStatus[]
}

export const getBookingRequests = ({
  checkInFrom,
  checkInTo,
  checkOutFrom,
  checkOutTo,
  page,
  regionIds,
  term,
  size,
  sources,
  statuses,
}: GetBookingRequestsProps) => {
  return arriereBackoffice.queries.adjustmentRequests({
    __alias: 'getBookingRequests',
    bookingRequests: {
      __args: {
        checkInFrom,
        checkInTo,
        checkOutFrom,
        checkOutTo,
        pagination: { page, size },
        regionIds,
        term,
        statuses,
        sources,
      },
      pages: true,
      total: true,
      results: {
        booking: {
          id: true,
          checkIn: true,
          checkOut: true,
          hash: true,
          guests: true,
          source: true,
          guestName: true,
          folio: {
            summary: {
              total: true,
              balance: true,
            },
          },
          home: {
            name: true,
            revenueModel: true,
            region: {
              name: true,
            },
          },
        },
        status: true,
        requests: {
          id: true,
          amounts: {
            id: true,
            requested: true,
            approved: true,
            refundType: true,
            refundedAt: true,
            refundSource: {
              ...refundPaymentSourceInputFragment,
            },
            requestId: true,
          },
          reason: {
            name: true,
            description: true,
            group: {
              name: true,
            },
          },
          createdAt: true,
          createdBy: {
            name: true,
          },
          reviewedAt: true,
          reviewedBy: {
            name: true,
          },
          statesHistory: {
            author: {
              name: true,
            },
            note: true,
          },
          requestedAmount: true,
        },
        totalRequested: true,
      },
    },
  })
}

export const getRefundTargets = async (bookingId: UUID) => {
  const { data, errors } = await arriereBackoffice.queries.adjustmentRequests.raw({
    __alias: 'getRefundTargets',
    refundTargets: {
      __args: { bookingId },
      balance: true,
      title: true,
      source: {
        ...refundPaymentSourceInputFragment,
      },
    },
  })
  if (errors?.length) {
    throw new Error(errors[0]?.message)
  }
  return data.refundTargets
}

export const createRefundListCSV = ({ ...variables }: GetBookingRequestsProps) => {
  const csvVariables = {
    ...variables,
    pagination: { page: variables.page, size: variables.size },
  }

  return fetchApi(
    REFUND_REQUESTS_QUERY,
    csvVariables,
    undefined,
    `${process.env.REACT_APP_ARRIERE_BACKOFFICE_CSV}`,
  )
}

// Mutations

export const requestAdjustment = ({ bookingId, requests }: RequestAdjustmentProps) => {
  return arriereBackoffice.mutations.adjustmentRequests.raw({
    __alias: 'createRequests',
    createRequests: {
      __args: {
        bookingId,
        requests,
      },
    },
  })
}

export const updateRequest = ({
  bookingId,
  requests,
  cancelRequestIds,
  cancelNote,
}: UpdateAdjustmentRequestProps) => {
  return arriereBackoffice.mutations.adjustmentRequests.raw({
    __alias: 'updateRequests',
    updateRequests: {
      __args: {
        bookingId,
        requests,
        cancelRequestIds,
        cancelNote,
      },
    },
  })
}

export const cancelAdjustment = ({ requestIds, note }: CancelAdjustmentProps) => {
  return arriereBackoffice.mutations.adjustmentRequests.raw({
    __alias: 'cancelAdjustmentRequests',
    cancelRequests: {
      __args: {
        requestIds,
        note,
      },
    },
  })
}

export const approveRequests = ({ bookingId, requests }: ApproveBookingRequestsProps) => {
  return arriereBackoffice.mutations.adjustmentRequests.raw({
    __alias: 'approveAdjustmentRequests',
    approveRequests: {
      __args: {
        bookingId,
        requests,
      },
    },
  })
}

export const rejectRequests = ({ requestIds, note }: RejectRequestProps) => {
  return arriereBackoffice.mutations.adjustmentRequests.raw({
    __alias: 'rejectAdjustmentRequests',
    rejectRequests: {
      __args: {
        requestIds,
        note,
      },
    },
  })
}

export const refundPaymentSource = ({ bookingId, methods }: RefundPaymentSourceProps) => {
  return arriereBackoffice.mutations.payments.raw({
    refundPaymentSource: {
      __args: {
        bookingId,
        methods,
      },
    },
  })
}

export const processManualRequests = ({ requests }: ProcessRequestProps) => {
  return arriereBackoffice.mutations.adjustmentRequests.raw({
    __alias: 'processManualRequests',
    processManualRequests: {
      __args: {
        requests,
      },
    },
  })
}
