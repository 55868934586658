import ReactMarkdown from 'react-markdown'
import styled from 'styled-components'

import { mainColors } from '../../styles/colors'
import { _M_Bold, _M_Medium, _M_Normal, _XS_Bold, _XS_Normal } from '../../styles/typography'

export const TooltipContainer = styled.div`
  max-width: 300px;
  ${_M_Normal};
  line-height: 20px;

  li + li {
    margin-top: 10px;
  }

  ul li::before {
    content: '';
    display: inline-block;
    margin: 0 10px 2px 0;
    background: white;
    width: 4px;
    height: 4px;
    border-radius: 50%;
  }

  strong {
    ${_M_Bold};
  }
`

export const TooltipTitle = styled.div`
  ${_M_Medium}
`

export const TooltipSectionTitle = styled.h4`
  color: ${mainColors.neutralExtremelyDark};
  ${_XS_Bold};

  &:not(:first-child) {
    margin-top: 15px;
  }
`

export const Markdown = styled(ReactMarkdown)`
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
`
