import React from 'react'

function _IconExternalLinks(props: React.SVGProps<SVGSVGElement>, ref?: React.Ref<SVGSVGElement>) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.9999 1.3999C9.66853 1.3999 9.3999 1.66853 9.3999 1.9999C9.3999 2.33127 9.66853 2.5999 9.9999 2.5999H12.5512L6.24214 8.90897C6.00783 9.14329 6.00783 9.52319 6.24214 9.7575C6.47646 9.99181 6.85636 9.99181 7.09067 9.7575L13.3999 3.44827V5.9999C13.3999 6.33127 13.6685 6.5999 13.9999 6.5999C14.3313 6.5999 14.5999 6.33127 14.5999 5.9999V1.9999C14.5999 1.66853 14.3311 1.3999 13.9997 1.3999H9.9999ZM3.33324 3.3999C2.82048 3.3999 2.32873 3.60359 1.96616 3.96616C1.60359 4.32873 1.3999 4.82048 1.3999 5.33324V12.6666C1.3999 13.1793 1.60359 13.6711 1.96616 14.0336C2.32873 14.3962 2.82048 14.5999 3.33324 14.5999H10.6666C11.1793 14.5999 11.6711 14.3962 12.0336 14.0336C12.3962 13.6711 12.5999 13.1793 12.5999 12.6666V8.66657C12.5999 8.3352 12.3313 8.06657 11.9999 8.06657C11.6685 8.06657 11.3999 8.3352 11.3999 8.66657V12.6666C11.3999 12.8611 11.3226 13.0476 11.1851 13.1851C11.0476 13.3226 10.8611 13.3999 10.6666 13.3999H3.33324C3.13874 13.3999 2.95222 13.3226 2.81469 13.1851C2.67716 13.0476 2.5999 12.8611 2.5999 12.6666V5.33324C2.5999 5.13874 2.67716 4.95222 2.81469 4.81469C2.95222 4.67716 3.13874 4.5999 3.33324 4.5999H7.33324C7.66461 4.5999 7.93324 4.33127 7.93324 3.9999C7.93324 3.66853 7.66461 3.3999 7.33324 3.3999H3.33324Z"
        fill="currentColor"
      />
    </svg>
  )
}

const IconExternalLinks = React.memo(React.forwardRef(_IconExternalLinks))

export { IconExternalLinks }
