import arriereBackoffice, {
  DateRangeInput,
  Granularity,
  IDate,
  LinkRevenueKindTitlesArgs,
  MergeAndAcquisitionIncluded,
  OwnerIncomeDashboardArgs,
  RevenueKindsArgs,
  RevenueKindTitlesArgs,
  RevenueModel,
} from '@avantstay/arriere-clients/dist/arriereBackoffice'
import { Alert } from '@avantstay/backoffice-core'

import {
  MarketPerformanceAggregationArgs,
  ProfitAndLossDashboardArgs,
} from '@new/domains/properties/screens/propertyPandLDashboard/propertyPerformance.types'

import {
  BookingVolumeArgsProps,
  CustomerExperienceIndicatorsProps,
  GlobalNPSIndicatorsProps,
  MarketNpsFilterProps,
  NpsIndicatorProps,
} from '@arriere/types/Dashboard.types'
import globalIndicators from '@queries/globalIndicators.graphql'
import { trackError } from '@tracking/customEventReport'
import fetchApi from '@utils/fetchApi'

interface IndicatorsFilter {
  from: IDate
  to: IDate
  regionIds?: string[]
  revenueModel?: RevenueModel
}

export const getNpsIndicator = ({
  from,
  to,
  lastClosedFromDate,
  lastClosedToDate,
  regionIds,
  alias,
  revenueModel,
}: NpsIndicatorProps) => {
  return arriereBackoffice.queries.globalIndicators.raw({
    __alias: alias,
    __args: { regionIds, revenueModel },
    customerExperience: {
      npsWithComparison: {
        __args: {
          from,
          to,
          comparison: { start: lastClosedFromDate, end: lastClosedToDate },
        },
        source: true,
        difference: true,
      },
    },
  })
}

export const getGlobalNPSIndicators = ({
  fromDate,
  toDate,
  lastClosedFromDate,
  lastClosedToDate,
  revenueModel,
  granularity,
  regionIds,
}: GlobalNPSIndicatorsProps) => {
  return arriereBackoffice.queries.globalIndicators.raw({
    __alias: 'getGlobalNps',
    __args: { granularity, revenueModel, regionIds },
    customerExperience: {
      npsScore: {
        __args: {
          from: fromDate,
          to: toDate,
          comparison: { start: lastClosedFromDate, end: lastClosedToDate },
        },
        source: true,
        comparison: true,
        difference: true,
      },
      npsCount: {
        __args: {
          from: fromDate,
          to: toDate,
          comparison: { start: lastClosedFromDate, end: lastClosedToDate },
        },
        source: true,
        comparison: true,
        difference: true,
      },
      npsConversion: {
        __args: {
          from: fromDate,
          to: toDate,
          comparison: { start: lastClosedFromDate, end: lastClosedToDate },
        },
        source: true,
        comparison: true,
        difference: true,
      },
    },
  })
}

export const getGlobalReviewCountComparison = async ({
  fromDate,
  toDate,
  lastClosedFromDate,
  lastClosedToDate,
  source,
}: CustomerExperienceIndicatorsProps) => {
  const { data, errors } = await arriereBackoffice.queries.globaReviewCountComparison.raw({
    __alias: `globalReviewCountComparison${source.toLowerCase()}`,
    __args: {
      from: fromDate,
      to: toDate,
      comparison: { start: lastClosedFromDate, end: lastClosedToDate },
      source,
    },
    source: true,
    comparison: true,
    difference: true,
  })
  if (errors?.length) {
    trackError(`Attempt to fetch ${source} data failed: ${errors[errors?.length - 1]?.message}`, {
      fromDate,
      toDate,
    })
    throw new Error(errors[0]?.message)
  }
  return data
}

export const getGlobalReviewAvgRatingComparison = async ({
  fromDate,
  toDate,
  lastClosedFromDate,
  lastClosedToDate,
  source,
}: CustomerExperienceIndicatorsProps) => {
  const { data, errors } = await arriereBackoffice.queries.globaReviewAvgRatingComparison.raw({
    __alias: `globalReviewAvgRatingComparison${source.toLowerCase()}`,
    __args: {
      from: fromDate,
      to: toDate,
      comparison: { start: lastClosedFromDate, end: lastClosedToDate },
      source,
    },
    source: true,
    comparison: true,
    difference: true,
  })
  if (errors?.length) {
    trackError(`Attempt to fetch ${source} data failed: ${errors[errors?.length - 1]?.message}`, {
      fromDate,
      toDate,
    })
    throw new Error(errors[0]?.message)
  }
  return data
}

export const getGlobalReviewConversionComparison = async ({
  fromDate,
  toDate,
  lastClosedFromDate,
  lastClosedToDate,
  source,
}: CustomerExperienceIndicatorsProps) => {
  return arriereBackoffice.queries.globaReviewConversionComparison
    .raw({
      __alias: `globalReviewConversionComparison${source.toLowerCase()}`,
      __args: {
        from: fromDate,
        to: toDate,
        comparison: { start: lastClosedFromDate, end: lastClosedToDate },
        source,
      },
      source: true,
      comparison: true,
      difference: true,
    })
    .then(({ data, errors }) => {
      if (errors?.length) {
        trackError(
          `Attempt to fetch ${source} data failed: ${errors[errors?.length - 1]?.message}`,
          {
            fromDate,
            toDate,
          },
        )
        throw new Error(errors[0]?.message)
      }

      return data
    })
}

export const getFullMarketNps = async ({ from, to, regionIds }: MarketNpsFilterProps) => {
  return arriereBackoffice.queries.npsByRegionAndMonth
    ?.raw({
      __args: { from, to, regionIds },
      __alias: 'getFullMarketNps',
      month: true,
      min: true,
      mean: true,
      median: true,
      regionMean: true,
      max: true,
      npsRegions: {
        regionId: true,
        npsView: {
          score: true,
        },
        region: {
          id: true,
          name: true,
          active: true,
        },
      },
    })
    .then(({ data, errors }) => {
      if (errors?.length) {
        throw new Error(errors[0]?.message)
      }

      return data
    })
}

export const getRecentBookingVolume = ({
  alias,
  createdAtFrom,
  createdAtUntil,
  leadTimeFrom,
  leadTimeUntil,
  mergeAndAcqIncluded,
  regionIds,
  revenueModel,
}: BookingVolumeArgsProps) => {
  return arriereBackoffice.queries.globalIndicators({
    __alias: alias,
    __args: { regionIds, revenueModel },
    finance: {
      bookingVolumePerCheckInPeriod: {
        __args: {
          createdAtFrom,
          createdAtUntil,
          leadTimeFrom,
          leadTimeUntil,
          mergeAndAcqIncluded,
        },
        amount: true,
      },
    },
  })
}

export const getSuperhostDashboard = () => {
  return arriereBackoffice.queries.superHostDashboard({
    __alias: 'getSuperhostDashboard',
    startNextSuperHostPeriod: true,
    totalReviewConversionRate: true,
    totalAccountsWithSuperhost: true,
    totalListingCountOfSuperhostAccounts: true,
    totalAccountsTrendingSuperhost: true,
    totalListingCountOfTrendingSuperhostAccounts: true,
    totalAccountsAtRiskOfLosingSuperhost: true,
    totalListingCountOfAtRiskOfLosingSuperhostAccounts: true,
  })
}

export const getGlobalIndicators = (params: {
  fromDate: IDate
  toDate: IDate
  fromComparativePeriod: IDate
  toComparativePeriod: IDate
  lastClosedFromDate: IDate
  lastClosedToDate: IDate
  regionIds?: string[]
  onlyStabilized?: boolean
  revenueModel?: RevenueModel
  granularity: Granularity
  comparison?: DateRangeInput
}) => {
  return fetchApi(globalIndicators, params)
}

export const getHomesCounting = ({ regionIds }: { regionIds?: string[] }) => {
  return arriereBackoffice.queries.homesCountingStats({
    __alias: 'homesCountingStats',
    __args: {
      regionIds,
    },
    signedUnitsCount: true,
    liveUnitsCount: true,
    onBoardingCount: true,
    onBoardedCount: true,
    preListedCount: true,
    listedCount: true,
    pipelineCount: true,
    rampingCount: true,
    newCount: true,
    stabilizedCount: true,
  })
}

export const getPlannedRevenue = () => {
  return [
    {
      date: '2024-08-01',
      booked: 18566813,
    },
    {
      date: '2024-09-01',
      booked: 13703910,
    },
    {
      date: '2024-10-01',
      booked: 13510087,
    },
    {
      date: '2024-11-01',
      booked: 13172883,
    },
    {
      date: '2024-12-01',
      booked: 17451852,
    },
    {
      date: '2025-01-01',
      booked: 16856678,
    },
    {
      date: '2025-02-01',
      booked: 17250363,
    },
    {
      date: '2025-03-01',
      booked: 24220213,
    },
    {
      date: '2025-04-01',
      booked: 19763269,
    },
    {
      date: '2025-05-01',
      booked: null,
    },
    {
      date: '2025-06-01',
      booked: null,
    },
    {
      date: '2025-07-01',
      booked: null,
    },
    {
      date: '2025-08-01',
      booked: null,
    },
  ]
}

export const getFutureRevenue = async ({ from, to, regionIds, revenueModel }: IndicatorsFilter) => {
  return arriereBackoffice.queries
    .globalIndicators({
      __alias: 'getFutureRevenue',
      __args: {
        regionIds,
        granularity: Granularity.monthly,
        revenueModel,
      },
      finance: {
        futureRevenue: {
          __args: {
            from,
            to,
            mergeAndAcqIncluded: MergeAndAcquisitionIncluded.all,
          },
          date: true,
          booked: true,
        },
      },
    })
    .then(response => response.finance)
}

export const getProfitAndLossDashboard = ({
  otaDemandMetricsPeriod,
  calendarAvailabilityPeriod,
  getAllListings,
}: ProfitAndLossDashboardArgs) => {
  return arriereBackoffice.queries
    .profitAndLossDashboard({
      __alias: 'profitAndLossDashboard',
      __args: {
        otaDemandMetricsPeriod,
        calendarAvailabilityPeriod,
        getAllListings,
      },
      results: {
        accountStatus: true,
        actualsLTM: true,
        acquisitionChannel: true,
        address: true,
        airbnbAppearanceInSearch: true,
        airbnbBookingConversionRate: true,
        airbnbBookings: true,
        airbnbImpressionsLastUpdatedAt: true,
        airbnbPDP: true,
        airbnbPDPConversionRate: true,
        airbnbPDPWeight: true,
        airbnbReviews: true,
        airbnbReviewsScore: true,
        airDnaLTM: true,
        assetType: true,
        bathrooms: true,
        bedrooms: true,
        bookingsByChannelL90D: {
          amount: true,
          source: true,
        },
        bookingVolumeL90D: true,
        completeAirdnaRentalizer: true,
        daysListed: true,
        financialHealth: true,
        guestReadinessStatus: true,
        halfBathrooms: true,
        homeOwnerNpsReviews: {
          period: true,
          score: true,
        },
        leaseCost: true,
        listedDate: true,
        listingStatus: true,
        liveDate: true,
        liveDays: true,
        ltmContributionMargin: true,
        ltmGuestNps: true,
        managementFee: true,
        market: true,
        marketId: true,
        maxOccupancy: true,
        numberOfReviews: true,
        ntmBookedRevenue: true,
        otaTotalBookingConversion: true,
        otaTotalPDPConversion: true,
        otaTotalSearchImpressions: true,
        partner: true,
        performance: true,
        portfolio: true,
        propertyId: true,
        propertyName: true,
        rating: true,
        responseTime: true,
        revenueModel: true,
        signedDate: true,
        superHost: true,
        terminationDate: true,
        isLTROnly: true,
        updatedAt: true,
        vrboBookingConversion: true,
        vrboPDPConversion: true,
        vrboReviews: true,
        vrboReviewsScore: true,
        vrboSearchImpressions: true,
        zipcode: true,
        y2Underwriting: true,
        january: true,
        february: true,
        march: true,
        april: true,
        may: true,
        june: true,
        july: true,
        august: true,
        september: true,
        october: true,
        november: true,
        december: true,
        directChannelsAppearanceInSearch: true,
        directChannelsBookingConversionRate: true,
        directChannelsPDPConversionRate: true,
        directChannelsPDP: true,
        availableNights: true,
        availableNightsNext90d: true,
        blockedNights: true,
        reservedNights: true,
        termEndDate: true,
        termStartDate: true,
        vrboImpressionsLastUpdatedAt: true,
        ownerEscalations: true,
        freshEscalations: true,
      },
    })
    .then(response => response.results)
}

export const getOwnerIncomeDashboard = ({
  pageSize,
  page,
  accountingPeriod,
  wildcardFilter,
  sortBy,
  sortOrder,
  showNetValues,
  homeId,
  stayPeriod,
}: OwnerIncomeDashboardArgs) => {
  return arriereBackoffice.queries
    .ownerIncomeDashboard({
      __alias: 'ownerIncomeDashboard',
      __args: {
        page,
        pageSize,
        accountingPeriod,
        wildcardFilter,
        sortBy,
        sortOrder,
        showNetValues,
        homeId,
        stayPeriod,
      },
      results: {
        accountingPeriod: true,
        accountingPeriodDays: true,
        bookingGuestName: true,
        bookingStatus: true,
        bookingCancellationDate: true,
        bookingOtaId: true,
        bookingVoyageHash: true,
        bookingVoyageUrl: true,
        bookingVoyageId: true,
        bookingReservationType: true,
        bookingSource: true,
        bookingCreatedAt: true,
        bookingCheckIn: true,
        bookingCheckOut: true,
        bookingConfirmationId: true,
        homeId: true,
        homeName: true,
        homeRevenueModel: true,
        homeUnitId: true,
        homeUnitName: true,
        homeUnitRevenueModel: true,
        revenueRentalIncome: true,
        revenueAccidentalDamageProtection: true,
        revenueCCFee: true,
        revenueCancellationFee: true,
        revenueChannelFeeDiscrepancy: true,
        revenueChannelManagementFee: true,
        revenueChefServices: true,
        revenueCleaningFee: true,
        revenueConciergeItems: true,
        revenueConsumablesProgram: true,
        revenueCouponDiscount: true,
        revenueDamageCollection: true,
        revenueDiscount: true,
        revenueDiscountAvantstayAbsorbed: true,
        revenueDiscountOwnerAsShared: true,
        revenueEarlyCheckIn: true,
        revenueElectronicLockFee: true,
        revenueEntertainment: true,
        revenueEventFee: true,
        revenueExperiences: true,
        revenueFine: true,
        revenueFirewood: true,
        revenueGet: true,
        revenueGolfCart: true,
        revenueGroceryServices: true,
        revenueGuestService: true,
        revenueHoaResortFee: true,
        revenueLateCheckOut: true,
        revenueLinenService: true,
        revenueMaintenanceFee: true,
        revenueMidStayCleaningFee: true,
        revenueOtaFee: true,
        revenuePetDeposit: true,
        revenuePetFee: true,
        revenuePoolSpaHeatingFee: true,
        revenueReservationFee: true,
        revenueSecurityDeposit: true,
        revenueService: true,
        revenueTot: true,
        revenueTaxesOwnerRemit: true,
        revenueTbid: true,
        revenueTat: true,
        revenueTransportation: true,
        revenueTravelInsurance: true,
        revenueUtilityIncome: true,
        revenueVasOther: true,
        revenueVaykBeachRentals: true,
        revenueWelcomeAmenityFee: true,
        revenueWellness: true,
        revenueOther: true,
        revenueNotOnReport: true,
        revenueTotal: true,
        revenueGolfCartCleaningFee: true,
        pages: true,
        rowsCount: true,
        revenueDisputeAdjustment: true,
      },
    })
    .then(response => response.results)
}

export const getMarketAggregatedPerformanceValues = ({
  market,
  otaDemandMetricsPeriod,
  calendarAvailabilityPeriod,
}: MarketPerformanceAggregationArgs) => {
  return arriereBackoffice.queries.marketPerformanceAggregation
    .raw({
      __alias: 'marketPerformanceAggregation',
      __args: {
        market,
        otaDemandMetricsPeriod,
        calendarAvailabilityPeriod,
      },
      results: {
        market: true,
        marketId: true,
        sumLTMRevenue: true,
        sumAirDnaUnderwriting: true,
        sumPerformance: true,
        sumConversions: true,
        sumNumberOfReviews: true,
        sumRating: true,
        count: true,
        avgLTMRevenue: true,
        avgAirDnaUnderwriting: true,
        avgPerformance: true,
        avgAirbnbAppearanceInSearch: true,
        avgAirbnbPDP: true,
        avgConversions: true,
        avgNumberOfReviews: true,
        avgRating: true,
        avgAirbnbPDPConversionRate: true,
        avgTotalOtaBookingConversion: true,
        avgTotalOtaSearchImpressions: true,
        avgTotalOtaPDPConversion: true,
        avgVrboBookingConversion: true,
        avgVrboSearchImpressions: true,
        avgVrboPDPConversion: true,
        avgLtmContributionMargin: true,
        avgDirectChannelsAppearanceInSearch: true,
        avgDirectChannelsBookingConversionRate: true,
        avgDirectChannelsPDPConversionRate: true,
        avgDirectChannelsPDP: true,
        avgY2Underwriting: true,
      },
    })
    .then(response => response.data.results)
}

export const getHomeownersNps = () => {
  return {
    updatedOn: '2024-06-27',
    overall: 31,
    numberOfDetractorsReviews: 65,
    numberOfPassivesReviews: 91,
    numberOfPromotersReviews: 164,
    numberOfReviews: 320,
  }
}

export const getAirbnbTripIssuesData = async () => {
  return arriereBackoffice.queries.airbnbDistribution
    .raw({
      __alias: 'getAirbnbTripIssuesData',
      aggregatedQualityStandards: {
        state: true,
        count: true,
        lastUpdatedAt: true,
      },
    })
    .then(({ data, errors }) => {
      if (errors?.length) {
        throw new Error(errors[errors.length - 1].message)
      }

      return data.aggregatedQualityStandards
    })
}

export const getRevenueKindTitles = ({
  year,
  month,
  titleFilter,
  kindId,
}: RevenueKindTitlesArgs) => {
  return arriereBackoffice.queries
    .revenueKindTitles({
      __alias: 'revenueKindTitles',
      __args: {
        year,
        month,
        titleFilter,
        kindId,
      },
      revenueKindId: true,
      title: true,
      mapped: true,
      affectedBookings: true,
      affectedBookingExamples: true,
      totalAmount: true,
    })
    .then(response => response)
}

export const getRevenueKinds = ({ year, month, nameFilter }: RevenueKindsArgs) => {
  return arriereBackoffice.queries
    .revenueKinds({
      __alias: 'revenueKinds',
      __args: {
        year,
        month,
        nameFilter,
      },
      id: true,
      name: true,
      nTitles: true,
      glAccount: true,
      lastModified: true,
      titles: {
        revenueKindId: true,
        title: true,
        mapped: true,
        affectedBookings: true,
        affectedBookingExamples: true,
        totalAmount: true,
      },
    })
    .then(response => response)
}

export const linkRevenueKindTitles = ({ titleByKind }: LinkRevenueKindTitlesArgs) => {
  return arriereBackoffice.mutations
    .linkRevenueKindTitles({
      __alias: 'linkRevenueKindTitles',
      __args: {
        titleByKind,
      },
    })
    .then(response => {
      Alert.Success('Revenue title linked successfully')
      return response
    })
}
