import arriereBackoffice, {
  AssetRequest,
  LeadSource,
} from '@avantstay/arriere-clients/dist/arriereBackoffice'

export default {
  createAssetMutation({
    context,
    extension,
    public: isPublic,
    targetId,
    size,
    fileName,
  }: AssetRequest) {
    return arriereBackoffice.mutations.createAsset({
      __args: {
        assetRequest: {
          context,
          extension,
          public: isPublic,
          targetId,
          size,
          fileName,
        },
      },
      url: true,
      assetId: true,
      externalUrl: true,
    })
  },
  getLeadSourceActiveAccounts({ source }: { source?: LeadSource }) {
    return arriereBackoffice.queries
      .leadSourceAccounts({
        __args: {
          source,
        },
        name: true,
        active: true,
      })
      .then(results => results.filter(it => it.active))
  },
}
