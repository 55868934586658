export const assetFragment = {
  id: true,
  context: true,
  s3Bucket: true,
  s3Key: true,
  public: true,
  targetId: true,
  createdAt: true,
  updatedAt: true,
  extension: true,
  size: true,
  fileName: true,
  url: true,
}
