import styled from 'styled-components'

import { _S_Bold, _S_Normal, mainColors, semanticColors } from '../../../../styles'
import { FilePreviewType, ImageContainerProps } from '../../UploadArea.types'
import { rgba } from '../../../../utils'
import { getFileSizeByType } from '../../UploadArea.utils'

export const getDetailContainerBackgroundColor = ({ $isCover, error }: ImageContainerProps) => {
  if ($isCover) {
    return mainColors.primaryMedium
  }

  if (error) {
    return semanticColors.failureMedium
  }

  return mainColors.neutralLight
}

// File Details
export const DetailsContainer = styled.div<ImageContainerProps>`
  position: absolute;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 4px 6px 10px;
  height: 32px;
  width: ${({ $viewMode }) =>
    $viewMode && getFileSizeByType(FilePreviewType.photoGallery, $viewMode).width}px;
  background-color: ${({ $isCover, error }) =>
    getDetailContainerBackgroundColor({ $isCover, error })};
  border-radius: 4px 4px 0 0;

  svg,
  p {
    color: ${({ $isCover, error }) =>
      $isCover || error ? mainColors.neutralExtraLight : mainColors.neutralExtraDark};
  }
`

export const FileTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
`

export const FileIndex = styled.p<{ error?: boolean }>`
  ${_S_Bold};
  color: ${mainColors.neutralExtremelyDark};
  ${({ error }) => error && 'opacity: 0.7'};
`

export const DetailActions = styled.div`
  display: grid;
  grid-auto-columns: 22px;
  grid-auto-flow: column;
  align-items: center;
  gap: 2px;

  > svg,
  > div {
    align-self: center;
    justify-self: center;
  }
`

export const FileInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  justify-content: center;
`

export const FileInfoLabel = styled.p`
  ${_S_Normal};
  color: ${mainColors.neutralDark};
`

export const FileInfoContent = styled.span`
  color: ${mainColors.white};
`

export const SelectImageButton = styled.button<{ isSelected?: boolean }>`
  position: relative;
  width: 18px;
  height: 18px;
  background-color: ${mainColors.white};
  border-radius: 50%;
  ${({ isSelected }) => !isSelected && `border: 2px solid ${mainColors.neutralDark}`};

  > svg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 18px;
    height: 18px;
    color: ${mainColors.white};
    background-color: ${mainColors.accentMedium};
    border-radius: 50%;
    opacity: ${({ isSelected }) => (isSelected ? 1 : 0)};
    transform: translate(-50%, -50%);
  }
`

export const ActionWrapper = styled.div<{ $primaryTheme?: boolean }>`
  position: absolute;
  right: 6px;
  top: 6px;
  cursor: pointer;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${rgba(mainColors.primaryMedium, 0.5)};
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 8px;
    height: 8px;
    color: ${mainColors.white};
  }

  ${({ $primaryTheme }) =>
    $primaryTheme &&
    `
    top: 4px;
    right: 4px;
    width: 24px;
    height: 24px;
    padding: 0;
    background-color: ${mainColors.primaryDark};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid ${mainColors.white};

    & > svg {
      width: 18px;
      height: 18px;
      color: ${mainColors.white};
    }
  `}
`
