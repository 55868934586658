import {
  BackOfficePrivilegeName,
  OnlineTravelAgency,
} from '@avantstay/arriere-clients/dist/arriereBackoffice'

import { OutlinedSectionConfigProps } from '@new/domains/properties/_types'
import { PropertyCustomFieldSections } from '@new/domains/properties/screens/propertyInfo/PropertyInfo.types'

import { AuthStore } from '@contexts/Auth/AuthStore'
import { ROUTES } from '@routes'
import { formatOTAName } from '@utils/formatSectionName'

// All Sections
export const propertyInfoRoutes = [
  ROUTES.propertyInfo.path,
  ROUTES.propertyInternalConfiguration.path,
  ROUTES.propertyHomeownerInfo.path,
  ROUTES.propertyDistributionChannels.path,
  ROUTES.propertyRooms.path,
  ROUTES.propertyLayout.path,
]

export const customFieldsSectionIdList = {
  [PropertyCustomFieldSections.generalConfiguration]: '0d730e32-86ff-11e9-b20d-0b15989c88b6',
  [PropertyCustomFieldSections.documents]: 'd6b38968-230b-11ea-a01a-503eaadea111',
  [PropertyCustomFieldSections.marketing]: '842915f4-14c5-11eb-8c33-dd673508989c',
  [PropertyCustomFieldSections.propertyMapping]: '341fe000-a93f-11eb-8136-673707c821db',
  [PropertyCustomFieldSections.leaseAndFinance]: 'ef596218-26f7-11ea-a680-503eaadea111',
  [PropertyCustomFieldSections.ownerAndLighthouse]: '6e58cc71-51f6-11eb-97e9-c792311be823',
  [PropertyCustomFieldSections.directAndOtherSettings]: 'a268c2bc-1c4a-11ea-b8f4-d787ffaf67ea',
}

export const MAX_INTERNAL_WIDTH = 476

export const propertyOutlinedSectionRoutes = [
  {
    label: 'Basic Info',
    path: ROUTES.propertyInfo.path,
  },
  {
    label: 'Cancellation Policies',
    path: ROUTES.propertyInfo.path,
  },
  {
    label: 'Lease Contracts',
    path: ROUTES.propertyHomeownerInfo.path,
  },
  {
    label: 'Owner Documents',
    path: ROUTES.propertyHomeownerInfo.path,
  },
  {
    label: 'Owner & Lighthouse',
    path: ROUTES.propertyHomeownerInfo.path,
    sectionId: customFieldsSectionIdList[PropertyCustomFieldSections.ownerAndLighthouse],
  },
  {
    label: 'Touchpoints',
    path: ROUTES.propertyHomeownerInfo.path,
    visible: () => AuthStore.hasUserPermission(BackOfficePrivilegeName.touchpointsRead),
  },
  {
    label: 'Documents',
    path: ROUTES.propertyInternalConfiguration.path,
    sectionId: customFieldsSectionIdList[PropertyCustomFieldSections.documents],
  },
  {
    label: 'General Configuration',
    path: ROUTES.propertyInternalConfiguration.path,
    sectionId: customFieldsSectionIdList[PropertyCustomFieldSections.generalConfiguration],
  },
  {
    label: 'Lease & Finance',
    path: ROUTES.propertyInternalConfiguration.path,
    sectionId: customFieldsSectionIdList[PropertyCustomFieldSections.leaseAndFinance],
  },
  {
    label: 'Lifecycle',
    path: ROUTES.propertyInternalConfiguration.path,
  },
  {
    label: 'Property Mapping',
    path: ROUTES.propertyInternalConfiguration.path,
    sectionId: customFieldsSectionIdList[PropertyCustomFieldSections.propertyMapping],
  },
  {
    label: 'Cancellation Policies',
    path: ROUTES.propertyDistributionChannels.path,
    sectionId: 'CANCELLATION_POLICIES',
  },
  {
    label: 'Direct & Other Settings',
    path: ROUTES.propertyDistributionChannels.path,
    sectionId: customFieldsSectionIdList[PropertyCustomFieldSections.directAndOtherSettings],
  },
  {
    label: 'Highlights',
    path: ROUTES.propertyDistributionChannels.path,
    sectionId: 'highlights',
  },
  {
    label: formatOTAName(OnlineTravelAgency.airbnb),
    path: ROUTES.propertyDistributionChannels.path,
    sectionId: OnlineTravelAgency.airbnb,
  },
  {
    label: formatOTAName(OnlineTravelAgency.rentalsUnited),
    path: ROUTES.propertyDistributionChannels.path,
    sectionId: OnlineTravelAgency.rentalsUnited,
  },
  {
    label: formatOTAName(OnlineTravelAgency.vrbo),
    path: ROUTES.propertyDistributionChannels.path,
    sectionId: OnlineTravelAgency.vrbo,
  },
] as OutlinedSectionConfigProps[]

// Homeowner Info
export const homeownerInfoCustomFieldsSections = [PropertyCustomFieldSections.ownerAndLighthouse]

// Internal Configuration
export const propertyInternalConfigurationCustomFieldsSections = [
  PropertyCustomFieldSections.generalConfiguration,
  PropertyCustomFieldSections.documents,
  PropertyCustomFieldSections.leaseAndFinance,
  PropertyCustomFieldSections.propertyMapping,
]

// Internal Configuration
export const propertyListingAndDistributionCustomFieldsSections = [
  PropertyCustomFieldSections.directAndOtherSettings,
]
