import React, { useState } from 'react'

import { asyncDebounce } from '../../../utils/asyncDebounce'
import {
  AsyncSelectFieldProps,
  OptionProps,
  OptionsGroup,
  SelectFieldProps,
} from '../SelectField.types'

const DEBOUNCE_TIME = 300

export function withOptionsLoading(
  SelectFieldComponent: React.ComponentType<SelectFieldProps<any>>,
) {
  return function <OptionValueType>(props: AsyncSelectFieldProps<OptionValueType>) {
    const [options, setOptions] = useState<
      OptionProps<OptionValueType>[] | OptionsGroup<OptionValueType>[]
    >([])
    const [isLoadingOptions, setIsLoadingOptions] = useState(false)
    const [error, setError] = useState<string | undefined>(undefined)

    const { loadOptions, onInputChange, ...selectFieldProps } = props

    const debouncedLoadOptions = asyncDebounce((inputValue: string) => {
      setIsLoadingOptions(true)
      return loadOptions(inputValue)
    }, DEBOUNCE_TIME)

    const handleInputChange = (inputValue: string) => {
      if (onInputChange) {
        onInputChange(inputValue)
      }

      debouncedLoadOptions(inputValue)
        .then(setOptions)
        // @ts-ignore
        .catch(e => {
          if (e) {
            // asyncDebounce is returning Promise.reject for canceled previous loads
            setError(e.message)
          }
        })
        .finally(() => setIsLoadingOptions(false))
    }

    return (
      <SelectFieldComponent
        options={options}
        isLoading={isLoadingOptions}
        onInputChange={handleInputChange}
        error={error}
        skipOptionsFiltering
        {...selectFieldProps}
      />
    )
  }
}
