import styled, { css } from 'styled-components'

import {
  _M_Bold,
  _M_Medium,
  _M_Normal,
  _S_Medium,
  _S_Normal,
  _XL_Bold,
  _XS_Medium,
  _XS_Normal,
} from '../../styles'

interface ParagraphProps {
  color?: string
}

const commonStyles = css<ParagraphProps>`
  color: ${({ color }) => color};
`

export const XSMedium = styled.p<ParagraphProps>`
  ${_XS_Medium};
  ${commonStyles};
`

export const XSNormal = styled.p<ParagraphProps>`
  ${_XS_Normal};
  ${commonStyles};
`

export const MBold = styled.p<ParagraphProps>`
  ${_M_Bold};
  ${commonStyles};
`

export const MMedium = styled.p<ParagraphProps>`
  ${_M_Medium};
  ${commonStyles};
`

export const MNormal = styled.p<ParagraphProps>`
  ${_M_Normal};
  ${commonStyles};
`

export const SNormal = styled.p<ParagraphProps>`
  ${_S_Normal};
  ${commonStyles};
`

export const SMedium = styled.p<ParagraphProps>`
  ${_S_Medium};
  ${commonStyles};
`

export const XLBold = styled.p<ParagraphProps>`
  ${_XL_Bold};
  ${commonStyles};
`
