import {
  Asset,
  CustomFieldRestriction,
  UUID,
} from '@avantstay/arriere-clients/dist/arriereBackoffice'

import { FilePreviewMode } from '@new/__ui/UploadArea/FilePreview/_enums'
import {
  AssetWithDocumentProps,
  UseManageAssetsStateProps,
} from '@new/__ui/UploadArea/hooks/useManageAssets'

export enum IDragAreaStatus {
  idle,
  dragOver,
}

export interface IDropAreaRoot {
  active: boolean
}

export enum UploadAreaType {
  document = 'DOCUMENT',
  general = 'GENERAL',
}

export interface UploadAreaProps {
  targetId: string
  uploadService: (
    targetId: string,
    file: File,
    fileName: string,
    progressFileFn: (id: string) => (progress: number) => void,
  ) => Promise<any>
  uploadSingleFile?: boolean
  filePreviewType?: FilePreviewType
  isSelecting?: boolean
  hidePreview?: boolean
  title?: string
  fieldRestriction?: CustomFieldRestriction
  initialFiles?: AssetWithDocumentProps[]
  areaStyles?: React.CSSProperties
  uploadAreaType?: UploadAreaType
  dropAreaMessage?: React.ReactNode
  viewMode?: FilePreviewMode
  selectedFiles?: UUID[]
  filePreviewActions?: ({ index }: { index: number }) => JSX.Element
  onChange?: (files: Asset[] | AssetWithDocumentProps[]) => void
  onChangeWithStatus?: ({
    files,
    filesUploadProgress,
    filesWithError,
  }: UseManageAssetsStateProps) => void
  onRemoveFile?: (file: AssetWithDocumentProps) => void
  onSelectFile?: (id: UUID) => void
  accept?: string
  disabled?: boolean
}

export enum FilePreviewType {
  documents = 'DOCUMENTS',
  customField = 'CUSTOM_FIELD',
  photoGallery = 'PHOTO_GALLERY',
}
