import { ReviewCategory } from '@avantstay/arriere-clients/dist/arriereBackoffice'

export interface CategoryReview {
  category: ReviewCategory
  rating: number
  comment?: string
}

interface GetOverallRatingProps {
  categoryReviews: CategoryReview[]
}

export const getOverallRating = ({ categoryReviews }: GetOverallRatingProps) => {
  const overallRating = categoryReviews.find(review => {
    return review.category === ReviewCategory.nps || review.category === ReviewCategory.overall
  })

  return overallRating
}

export const labelByReviewCategory: Record<string, string> = {
  [ReviewCategory.onlineListing]: 'Accuracy',
  [ReviewCategory.checkIn]: 'Check-In',
}
