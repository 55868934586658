import arriereBackoffice from '@avantstay/arriere-clients/dist/arriereBackoffice'
import arriereLighthouse from '@avantstay/arriere-clients/dist/arriereLighthouse'

import type {
  AddBookingGuestVariables,
  AssignOwnerToHomeVariables,
  CreateBookingGuestVariables,
  GetGuestsListVariables,
  GuestBookingsResponse,
  GuestCredit,
  GuestCreditEdit,
  GuestCreditWithdraw,
  GuestProjectionCreditHistoryFragment,
  InviteOwnerToLighthouseResults,
  InviteOwnerToLighthouseVariables,
  OwnerStatusResults,
  OwnerStatusVariables,
  UpdateGuestVariables,
} from '@new/domains/guests/Guests.types'

const bookingFragment = {
  id: true,
  hash: true,
  source: true,
  status: true,
  period: {
    start: true,
    end: true,
  },
  home: {
    name: true,
    title: true,
    region: {
      name: true,
    },
  },
  folio: {
    summary: {
      isPaid: true,
      total: true,
      balance: true,
      paid: {
        total: true,
        credits: true,
      },
      value: true,
    },
  },
  customFields: {
    __args: {},
    section: {
      id: true,
      name: true,
    },
    fields: {
      id: true,
      name: true,
      value: true,
    },
  },
}

const guest = {
  guestId: true,
  userId: true,
  tags: {
    guestTags: {
      full: {
        tag: {
          id: true,
          name: true,
          vipLevel: true,
        },
      },
    },
  },
  name: true,
  emails: true,
  phones: true,
  note: true,
  accountCreatedAt: true,
  totalSessions: {
    total: true,
    latestLogin: true,
  },
  hasApp: true,
  totalReviews: true,
  address: {
    address: true,
    zipCode: true,
    city: true,
    state: {
      name: true,
    },
  },
  credits: true,
  stays: {
    canceled: true,
    ongoing: true,
    past: true,
    total: true,
    upcoming: true,
  },
  totalNights: true,
}

export default {
  async getGuestsList({
    pagination,
    terms,
    guestTags,
    sortOrder,
    sorting,
  }: GetGuestsListVariables) {
    return arriereBackoffice.queries.guests
      .memoRaw({
        __args: { pagination, terms, guestTags, sortOrder, sorting },
        total: true,
        pages: true,
        paginationParams: {
          page: true,
          size: true,
        },
        results: guest,
      })
      .then(response => response.data)
  },

  async getGuestById(id: string) {
    return arriereBackoffice.queries.guest({
      __args: { id },
      ...guest,
    })
  },

  async getGuestsCountByTag() {
    return arriereBackoffice.queries.guestsCountByTag({
      tag: {
        id: true,
        name: true,
        vipLevel: true,
        description: true,
        manuallyApplicable: true,
      },
      guestsCount: true,
    })
  },

  async updateGuestInfo({ id, tagsIds, note }: UpdateGuestVariables) {
    return arriereBackoffice.mutations.updateGuestData({
      __args: {
        id,
        tagsIds,
        note,
      },
    })
  },

  async bookingsOfGuest(guestId: string): Promise<GuestBookingsResponse> {
    return arriereBackoffice.queries.bookingsOf({
      __args: {
        guestId,
      },
      ongoing: {
        ...bookingFragment,
      },
      upcoming: {
        __args: {},
        results: {
          ...bookingFragment,
        },
      },
      previous: {
        __args: {},
        results: {
          ...bookingFragment,
        },
      },
      canceled: {
        __args: {},
        results: {
          ...bookingFragment,
        },
      },
    })
  },

  async changeCreditExpirationDate({
    personId,
    walletTransactionId,
    expiration,
  }: GuestCreditEdit): Promise<boolean> {
    return arriereBackoffice.mutations.changeCreditExpirationDate({
      __args: { personId, walletTransactionId, expiration },
    })
  },

  async addCreditToGuest({
    personId,
    cents,
    expiration,
    reason,
    relatedBookingId,
  }: GuestCredit): Promise<boolean> {
    return arriereBackoffice.mutations.addCredit({
      __args: {
        personId,
        cents,
        expiration,
        reason,
        relatedBookingId: relatedBookingId || undefined,
      },
    })
  },

  async withdrawCredit({
    personId,
    walletTransactionId,
    amount,
    reason,
  }: GuestCreditWithdraw): Promise<boolean> {
    return arriereBackoffice.mutations.withdrawCredit({
      __args: { personId, walletTransactionId, amount, reason },
    })
  },

  async inviteOwnerToLighthouse({
    email,
    ownerName,
  }: InviteOwnerToLighthouseVariables): Promise<InviteOwnerToLighthouseResults> {
    return arriereLighthouse.mutations.user({
      inviteOwnerToLighthouse: {
        __args: { email, ownerName },
      },
    })
  },

  async assignOwnerToHome({ ownerEmail, homeId }: AssignOwnerToHomeVariables): Promise<boolean> {
    return arriereBackoffice.mutations.assignOwnerToHome({
      __args: { ownerEmail, homeId },
    })
  },

  async getGuestCreditsHistory(personId: string): Promise<GuestProjectionCreditHistoryFragment[]> {
    return arriereBackoffice.queries.creditsHistory({
      __args: { personId },
      transaction: {
        id: true,
        amount: true,
        personId: true,
        kind: true,
        parentTransactionId: true,
      },
      expiration: true,
      reason: true,
      received: true,
      inviterOrSubscriber: true,
      operators: {
        requestOperator: {
          id: true,
          name: true,
        },
        editingOperator: {
          id: true,
          name: true,
        },
      },
      balance: true,
    })
  },

  async ownerStatus({ email, homeId }: OwnerStatusVariables): Promise<OwnerStatusResults> {
    return arriereLighthouse.queries.user
      .raw({
        ownerStatus: {
          __args: { email, homeId },
          registeredInLighthouse: true,
          ownsSpecifiedProperty: true,
        },
      })
      .then(r =>
        r.status >= 200 && r.status < 300
          ? r.data
          : Promise.reject(new Error(r?.errors?.[0]?.message || '')),
      )
  },

  async getGuestSessions({ email, limit }: { email: string; limit?: number }) {
    return arriereBackoffice.queries.fullstorySessions({
      __args: { email, limit },
      sessionId: true,
      url: true,
      userId: true,
      created: true,
    })
  },

  async findGuests(term: string) {
    return arriereBackoffice.queries.bookingGuestCandidates({
      __args: { contactInfoTerm: term },
      results: {
        ...guest,
      },
    })
  },

  async addBookingGuest({ bookingId, guestId, note, tagsIds }: AddBookingGuestVariables) {
    return arriereBackoffice.mutations.guests.raw({
      __alias: 'addBookingGuest',
      addBookingGuest: {
        __args: { bookingId, guestId, note, tagsIds },
      },
    })
  },

  async createBookingGuest({
    bookingId,
    email,
    fullName,
    note,
    phone,
    tagsIds,
  }: CreateBookingGuestVariables) {
    return arriereBackoffice.mutations.guests.raw({
      __alias: 'createBookingGuest',
      createBookingGuest: {
        __args: { bookingId, email, fullName, note, phone, tagsIds },
      },
    })
  },
}
