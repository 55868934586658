import React from 'react'

function _IconAvantstayLogo(props: React.SVGProps<SVGSVGElement>, ref?: React.Ref<SVGSVGElement>) {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" {...props} ref={ref}>
      <path
        d="M10.0879 16.8992C10.3174 16.8408 10.4104 16.8241 10.4996 16.7946C12.8117 16.0139 15.135 15.2711 17.4921 14.6413C17.9721 14.5132 18.0658 14.357 17.8483 13.8909C16.8771 11.8005 15.9405 9.69425 14.9843 7.59705C14.9175 7.45001 14.8605 7.28099 14.7038 7.28099C14.5463 7.28099 14.4976 7.44698 14.4338 7.58265C13.7596 9.02121 13.0907 10.462 12.4195 11.9013C11.6598 13.5301 10.8993 15.1589 10.0879 16.8992ZM24.4516 26.988C24.2934 26.988 24.1216 26.9827 23.9199 26.8697C23.6567 26.7227 23.5449 26.4559 23.4924 26.3392C21.9648 22.9437 20.4049 19.564 18.8892 16.1632C18.6613 15.6516 18.4498 15.5114 17.9046 15.6827C16.2914 16.1897 14.6258 16.5217 13.0457 17.1417C11.5915 17.7117 11.4738 18.1838 12.7412 19.0896C13.2586 19.4594 13.7679 19.846 14.2088 20.3114C15.129 21.2823 15.072 22.2282 13.9936 23.0195C11.596 24.7771 8.87221 25.7366 5.98117 26.2513C4.49852 26.5158 3.00613 26.609 1.50774 26.371C0.926527 26.2793 0.333319 26.152 0.0850872 25.5343C-0.160895 24.9234 0.172831 24.4269 0.496808 23.9221C1.15676 22.8936 2.24193 22.9831 3.22061 22.8322C3.54984 22.7815 3.62333 23.0952 3.42535 23.3582C3.22886 23.6205 2.97163 23.8365 2.7789 24.1003C2.38067 24.6437 2.48192 24.9219 3.14337 25.0765C3.80632 25.2311 4.48802 25.2038 5.15398 25.1189C6.9471 24.8908 8.65623 24.3981 10.1389 23.2969C10.9608 22.6867 10.9593 22.2843 10.1344 21.6991C9.42717 21.1966 8.69522 20.7282 7.98802 20.225C7.31232 19.7437 7.17208 19.1828 7.49681 18.4552C9.30643 14.4003 11.1153 10.3453 12.9227 6.28886C13.6014 4.76618 14.2808 3.24273 14.949 1.71474C15.123 1.3191 15.3195 0.997738 15.8167 1.00001C16.3057 1.00229 16.5209 1.30773 16.7009 1.70337C19.3137 7.45987 21.9318 13.2133 24.5476 18.9675C25.647 21.3861 26.7457 23.8047 27.8414 26.2247C27.9883 26.5499 28.0401 26.7454 27.9673 26.8584C27.8691 27.0107 27.6171 27.0031 27.3441 26.9978C27.0067 26.9918 26.5027 26.9865 25.8442 26.9971C25.383 26.988 24.9128 26.9971 24.4516 26.988Z"
        fill="white"
      />
    </svg>
  )
}

const IconAvantstayLogo = React.memo(React.forwardRef(_IconAvantstayLogo))

export { IconAvantstayLogo }
