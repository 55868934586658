import arriereBackoffice, {
  AssetUploadInfo,
  BookKind,
  DateRangeInput,
  Granularity,
  Highlight,
  HomeBookSectionInput,
  HomeInvoiceType,
  HomeInvoiceWithUrl,
  HomeSorting,
  IDate,
  LeadSource,
  MergeAndAcquisitionIncluded,
  PriceInput,
  RateType,
  SeasonPriceInput,
  UUID,
} from '@avantstay/arriere-clients/dist/arriereBackoffice'
import { Alert } from '@avantstay/backoffice-core'

import type {
  HomeTaxAgencyOverrideChangeLogProps,
  PropertyRestrictionsInfoProps,
} from '@new/domains/bookings/stores/_types'
import type { TapeChartPropertiesFilterProps } from '@new/domains/bookingsTapeChart/BookingsTapeChart.types'
import type { CellData } from '@new/domains/properties/_types'
import type { Amenity } from '@new/domains/properties/screens/propertyAmenities/PropertyAmenities.types'
import type { UpdateCancellationPoliciesProps } from '@new/domains/properties/screens/propertyDistributionChannels/CancellationPolicies/CancellationPolicies.types'
import type {
  HomeCustomerExperienceIndicators,
  HomesDistributionsProps,
  SyncOtaDistributionMutation,
  UpdateHomeListingMutation,
} from '@new/domains/properties/screens/propertyDistributionChannels/DistributionChannels.types'
import { UpdateCheckTime } from '@new/domains/properties/screens/propertyInfo/BasicInfo/BasicInfo.types'
import type { UpdateLifecycle } from '@new/domains/properties/screens/propertyLifecycle/Lifecycle.type'
import type { CreateHomeImageProps } from '@new/domains/properties/screens/propertyPhotoGallery/PropertyPhotoGallery.types'
import type { PropertyRevenueConfigurationMutation } from '@new/domains/properties/screens/propertyRevenueConfiguration/PropertyRevenueConfiguration.types'
import type { GetPropertyReviewsProps } from '@new/domains/properties/screens/propertyReviews/PropertyReviews.types'
import { UpdateServiceFrequencyOnPropertyLevel } from '@new/domains/properties/screens/propertyServicesAndVendors/components/ServiceFrequencyModal/'
import type { UpdateTaxesAndFeesMutation } from '@new/domains/properties/screens/propertyTaxesAndFees/__types'

import { assetFragment } from '@arriere/modules/fragments/asset'
import { basicIndicatorInfo, indicatorInfo } from '@arriere/modules/fragments/indicators'
import { npsRatingsOverviewFragment, ratingsOverviewFragment } from '@arriere/modules/regions'
import {
  HomeIndicatorParams,
  IBuyOutsMutationParams,
  IUpdateHomeParams,
  PaginationInput,
  PropertiesReviewsParams,
  UpdatePropertyHighlightProps,
  VerifyPropertyInfoProps,
} from '@arriere/types/Properties.types'
import { UpdateHomeImageProps } from '@arriere/types/PropertyPhotoGallery.types'
import { REVENUE_CONFIGURATION_SECTION_ID } from '@utils/customFields'

import { UnsetPriceInput } from '../types/PricingTapeChart.types'

export const getPropertyFamilyStatus = async (homeId: string) => {
  return arriereBackoffice.queries.home({
    __alias: 'getPropertyFamilyStatus',
    __args: { homeId },
    id: true,
    isAncestor: true,
    isSuccessor: true,
  })
}

export const getPropertyById = async (homeId: string) => {
  return arriereBackoffice.queries.home({
    __alias: 'getPropertyById',
    __args: { homeId },
    id: true,
    bathroomsCount: true,
    checkTime: {
      checkOut: true,
    },
    description: true,
    halfBathroomsCount: true,
    hash: true,
    isAncestor: true,
    latitude: true,
    longitude: true,
    maxAdults: true,
    maxOccupancy: true,
    maxPets: true,
    name: true,
    shortDescription: true,
    title: true,
    thumbnail: {
      url: true,
    },
    verifyItems: {
      item: true,
      verifiedAt: true,
      author: {
        name: true,
        kind: true,
      },
    },
    address: {
      address: true,
      city: true,
      countryCode: true,
      zipCode: true,
      state: {
        name: true,
      },
      region: { name: true, timezone: true },
      regionId: true,
      stateId: true,
    },
    channels: {
      title: true,
      source: true,
    },
    activeLeaseMonthlyAmount: true,
    customFields: {
      __args: { tags: undefined },
      section: {
        id: true,
        name: true,
        readOnly: true,
      },
      fields: {
        id: true,
        kind: true,
        value: true,
        name: true,
        options: true,
        restriction: true,
        internal: true,
        tags: true,
        createdAt: true,
        updatedAt: true,
        tooltip: true,
        lastUpdatedAt: true,
        lastUpdatedBy: {
          kind: true,
          name: true,
        },
        lastVerifiedAt: true,
        lastVerifiedBy: {
          kind: true,
          name: true,
        },
        assets: {
          id: true,
          fileName: true,
          size: true,
          createdAt: true,
          url: true,
        },
        requiresVerification: true,
        userAllowedActions: true,
        requiredPrivileges: {
          name: true,
          category: true,
          action: true,
        },
      },
    },
    links: {
      manual: true,
      listings: {
        leadSource: true,
        url: true,
        kind: true,
      },
    },
    rooms: {
      id: true,
      kind: true,
    },
    buyouts: {
      id: true,
      name: true,
      region: {
        name: true,
      },
    },
    blockedHomes: {
      id: true,
      name: true,
      region: {
        name: true,
      },
    },
    cancellationPolicies: {
      channelCancellationPolicyCategoryId: true,
      name: true,
      source: true,
      updatedAt: true,
      updatedBy: {
        name: true,
      },
    },
  })
}

export const getPropertyByIdHeaderInfo = async (homeId: string) => {
  return arriereBackoffice.queries.home({
    __alias: 'getPropertyByIdHeaderInfo',
    __args: { homeId },
    id: true,
    name: true,
    title: true,
    revenueModel: true,
    econPropertyFinancialHealth: true,
    customFields: {
      __args: { tags: undefined },
      section: {
        id: true,
      },
      fields: {
        id: true,
        value: true,
        name: true,
      },
    },
    details: {
      onBoardingStatus: true,
    },
    distributions: {
      __args: { activeOnly: false },
      agencyAccount: {
        id: true,
      },
    },
    links: {
      manual: true,
      listings: {
        leadSource: true,
        url: true,
        kind: true,
      },
    },
    ongoingBooking: {
      hash: true,
      guestName: true,
      detailedCheckIn: {
        effectiveTime: true,
      },
      detailedCheckOut: {
        effectiveTime: true,
      },
    },
    region: {
      id: true,
      name: true,
    },
    thumbnail: {
      url: true,
    },
    hotelId: true,
    lifecycle: {
      listingStatus: true,
      riskLevel: true,
    },
  })
}

export const getPropertyByIdMinimalInfo = async (homeId: string) => {
  return arriereBackoffice.queries.home({
    __alias: 'getPropertyById',
    __args: { homeId },
    id: true,
    name: true,
    thumbnail: {
      url: true,
    },
    region: {
      name: true,
    },
  })
}

export const getPropertyByIdLocationSummary = async (homeId: string) => {
  return arriereBackoffice.queries.home({
    __alias: 'getPropertyByIdLocationSummary',
    __args: { homeId },
    id: true,
    bathroomsCount: true,
    bedsCount: true,
    halfBathroomsCount: true,
    maxOccupancy: true,
    maxPets: true,
    name: true,
    revenueModel: true,
    customFields: {
      __args: { tags: undefined },
      section: {
        id: true,
      },
      fields: {
        id: true,
        value: true,
        name: true,
      },
    },
    details: {
      onBoardingStatus: true,
    },
    lifecycle: {
      listingStatus: true,
      riskLevel: true,
    },
    links: {
      manual: true,
      listings: {
        leadSource: true,
        url: true,
      },
    },
    region: {
      id: true,
      name: true,
    },
    rooms: {
      id: true,
      kind: true,
    },
    thumbnail: {
      url: true,
    },
  })
}

export const getPropertyByIdInTapeChart = async ({
  homeId,
  from,
  to,
}: {
  homeId?: string
  from?: IDate
  to?: IDate
}) => {
  return arriereBackoffice.queries.home({
    __alias: 'getPropertyByIdInTapeChart',
    __args: { homeId },
    id: true,
    title: true,
    shortDescription: true,
    maxAdults: true,
    bathroomsCount: true,
    bedsCount: true,
    halfBathroomsCount: true,
    maxOccupancy: true,
    maxPets: true,
    name: true,
    revenueModel: true,
    customFields: {
      __args: { tags: undefined },
      section: {
        id: true,
      },
      fields: {
        id: true,
        value: true,
        name: true,
      },
    },
    details: {
      onBoardingStatus: true,
    },
    lifecycle: {
      listingStatus: true,
      riskLevel: true,
    },
    links: {
      manual: true,
      listings: {
        leadSource: true,
        url: true,
      },
    },
    region: {
      id: true,
      name: true,
    },
    rooms: {
      id: true,
      kind: true,
    },
    thumbnail: {
      url: true,
    },
    bookings: {
      __args: {
        leadSources: [],
        from,
        to,
      },
      results: {
        id: true,
        hash: true,
        source: true,
        detailedCheckIn: {
          effectiveTime: true,
        },
        detailedCheckOut: {
          effectiveTime: true,
        },
        guestName: true,
        guests: true,
        status: true,
      },
    },
    calendar: {
      __args: { from: from || '', to: to || '' },
      date: true,
      status: true,
      restrictions: {
        checkInAllowed: true,
        checkOutAllowed: true,
      },
    },
  })
}

export const propertyInfoFieldsToVerify = () => {
  return arriereBackoffice.queries.verifyItems({
    __alias: 'getFieldsToVerify',
    name: true,
    required: true,
  })
}

export const getLifecycle = (homeId: string) => {
  return arriereBackoffice.queries.home({
    __alias: 'getLifecycle',
    __args: { homeId },
    lifecycle: {
      accountStatus: true,
      guestReadinessStatus: true,
      designStatus: true,
      listingStatus: true,
      closeDate: true,
      commencementDate: true,
      agreementStartDate: true,
      guestReadyDate: true,
      earlyListedDate: true,
      listedDate: true,
      listingNotes: true,
      operatingLaunchDate: true,
      uwOperationalLaunchDate: true,
      firstBookingDate: true,
      firstStayDate: true,
      terminationNoticeDate: true,
      finalRentableDate: true,
      terminationDate: true,
      riskLevel: true,
      riskReason: true,
      revenueModelChangeDate: true,
    },
  })
}

export default {
  async getAllHighlights() {
    return arriereBackoffice.queries.highlights({
      __alias: 'getAllHighlights',
      id: true,
      name: true,
    })
  },
  async getPropertyByIdHighlights(homeId?: string) {
    return homeId === undefined
      ? undefined
      : arriereBackoffice.queries.home({
          __alias: 'getPropertyByIdHighlights',
          __args: { homeId },
          highlights: {
            highlightId: true,
            homeId: true,
            name: true,
            enabled: true,
            createdAt: true,
            listOrder: true,
            showOnSearch: true,
            shortDescription: true,
            assetId: true,
            coverImage: {
              id: true,
              context: true,
              s3Bucket: true,
              s3Key: true,
              public: true,
              createdAt: true,
              url: true,
              fileName: true,
            },
          },
        })
  },

  async getCancellationPolicyCategories() {
    return arriereBackoffice.queries.cancellationPolicyCategories({
      __alias: 'getCancellationPolicyCategories',
      id: true,
      description: true,
      name: true,
      source: true,
      items: {
        id: true,
        name: true,
        description: true,
      },
    })
  },

  async getTapeChartPropertyFilterCounters(
    tapeChartPropertiesFilter?: Omit<TapeChartPropertiesFilterProps, 'pagination'>,
  ) {
    return arriereBackoffice.queries.tapeChartCounters({
      __args: tapeChartPropertiesFilter ?? {},
      revenueModel: {
        name: true,
        value: true,
      },
      riskLevel: {
        name: true,
        value: true,
      },
    })
  },

  async getPropertyTaxesAndFeesById(homeId?: string) {
    return homeId === undefined
      ? undefined
      : arriereBackoffice.queries.home({
          __alias: 'getPropertyTaxesAndFeesById',
          __args: { homeId },
          taxes: {
            tax: {
              isTax: true,
              embedded: true,
              enabled: true,
              name: true,
              value: true,
              valueType: true,
              unit: true,
              revenueTargets: true,
              id: true,
              archived: true,
              lastUpdateMeta: {
                changedAt: true,
                changedBy: true,
              },
            },
            enabled: true,
            overrides: {
              agency: true,
              value: true,
            },
          },
        })
  },

  async getPropertySuccessors(homeId: string) {
    return arriereBackoffice.queries.successors({
      __args: { homeId },
    })
  },

  async getFinancialHealthDescription() {
    return arriereBackoffice.queries.econPropertyFinancialHealthDescription({
      color: true,
      description: true,
    })
  },

  async getHomeCustomFieldsForOTA(homeId: string, sectionId: string) {
    return arriereBackoffice.queries.home({
      __alias: 'getHomeCustomFieldsForOTA',
      __args: {
        homeId,
      },
      customFields: {
        __args: {
          sectionIds: [sectionId],
          withDisabled: true,
        },
        section: {
          id: true,
          name: true,
        },
        fields: {
          id: true,
          kind: true,
          name: true,
          value: true,
          tooltip: true,
          restriction: true,
        },
      },
    })
  },

  async getPropertyLeases(homeId: string) {
    return homeId === undefined
      ? undefined
      : arriereBackoffice.queries.home({
          __alias: 'getPropertyLeases',
          __args: {
            homeId,
          },
          leases: {
            startDate: true,
            endDate: true,
            amount: true,
            notes: true,
            signDate: true,
          },
        })
  },
  async getPropertyImages(homeId: string) {
    return homeId === undefined
      ? undefined
      : arriereBackoffice.queries
          .home({
            __args: {
              homeId,
            },
            images: {
              listOrder: true,
              description: true,
              roomId: true,
              asset: {
                ...assetFragment,
              },
            },
          })
          .then(results => results.images)
  },
  async getThermostatsPerProperty(homeId: string) {
    return homeId === undefined
      ? undefined
      : arriereBackoffice.queries.home({
          __alias: 'getThermostatsPerProperty',
          __args: {
            homeId,
          },
          id: true,
          thermostats: {
            id: true,
            hubId: true,
            name: true,
            serialNumber: true,
            online: true,
            temperature: true,
            triggeredAt: true,
          },
        })
  },

  async getNoiseLevelDevicesPerProperty(homeId: string, pagination: PaginationInput) {
    return homeId === undefined
      ? undefined
      : arriereBackoffice.queries.home({
          __alias: 'getNoiseLevelDevicesPerProperty',
          __args: {
            homeId,
          },
          noiseLevelDevices: {
            __args: { pagination },
            results: {
              id: true,
              externalId: true,
              name: true,
              location: true,
              status: true,
              image: true,
              battery: true,
              updatedAt: true,
              reading: {
                threshold: true,
                noiseLevel: true,
                readAt: true,
              },
            },
          },
        })
  },

  async getOccupancyDevicesPerProperty(homeId: string, pagination: PaginationInput) {
    return homeId === undefined
      ? undefined
      : arriereBackoffice.queries.home({
          __alias: 'getOccupancyDevicesPerProperty',
          __args: {
            homeId,
          },
          maxOccupancy: true,
          occupancyDevices: {
            __args: { pagination },
            results: {
              id: true,
              name: true,
              externalId: true,
              threshold: true,
              updatedAt: true,
              reading: {
                occupancy: true,
                readAt: true,
              },
            },
          },
        })
  },
  async getHomeSummaryIndicators({
    homeId,
    fromDate,
    toDate,
    granularity,
    pagination,
  }: HomeIndicatorParams) {
    return arriereBackoffice.queries.homes({
      __alias: 'getHomeSummaryIndicators',
      __args: { homeIds: [homeId], pagination },
      results: {
        id: true,
        indicators: {
          __args: { granularity },
          finance: {
            adr: {
              __args: { from: fromDate, to: toDate },
              period: true,
            },
            bookingVolume: {
              __args: {
                from: fromDate,
                to: toDate,
                mergeAndAcqIncluded: MergeAndAcquisitionIncluded.withoutMa,
              },
              aggregatedValue: true,
              annualizedValue: true,
            },
          },
          sales: {
            occupancyRate: {
              __args: { from: fromDate, to: toDate },
              values: {
                value: true,
              },
              aggregatedValue: true,
            },
          },
        },
      },
    })
  },
  async getHomeIndicators({
    homeId,
    fromDate,
    toDate,
    granularity,
    pagination,
  }: HomeIndicatorParams) {
    return arriereBackoffice.queries.homes({
      __alias: 'getHomeIndicators',
      __args: { homeIds: [homeId], pagination },
      results: {
        indicators: {
          __args: { granularity },
          customerExperience: {
            airbnbSuperhostRating: {},
            airbnbFiveStarReviewsToSuperhost: {},
            airbnbSuperhostPeriod: {
              end: true,
              start: true,
            },
            nps: {},
          },
          finance: {
            adr: {
              __args: { from: fromDate, to: toDate },
              period: true,
            },
            revpau: {
              __args: { from: fromDate, to: toDate },
              period: true,
              weekdays: true,
              weekends: true,
            },
            bookingVolume: {
              __args: {
                from: fromDate,
                to: toDate,
                mergeAndAcqIncluded: MergeAndAcquisitionIncluded.withoutMa,
              },
              ...indicatorInfo,
              annualizedValue: true,
            },
            realizedRevenue: {
              __args: { from: fromDate, to: toDate },
              ...indicatorInfo,
              annualizedValue: true,
            },
            channelDistribution: {
              __args: {
                from: fromDate,
                to: toDate,
                mergeAndAcqIncluded: MergeAndAcquisitionIncluded.all,
              },
              channelName: true,
              percentage: true,
              total: true,
            },
          },
          sales: {
            averageBookingLength: {
              __args: { from: fromDate, to: toDate },
              ...indicatorInfo,
            },
            medianBookingLeadTime: {
              __args: { from: fromDate, to: toDate },
              ...indicatorInfo,
            },
            occupancyRate: {
              __args: { from: fromDate, to: toDate },
              ...indicatorInfo,
            },
            weekendOccupancyRate: {
              __args: { from: fromDate, to: toDate },
              ...indicatorInfo,
            },
            averageGroupSize: {
              __args: { from: fromDate, to: toDate },
              ...basicIndicatorInfo,
            },
            cancelledBookings: {
              __args: { from: fromDate, to: toDate },
              ...basicIndicatorInfo,
            },
            returningGuests: {
              __args: { from: fromDate, to: toDate },
              ...basicIndicatorInfo,
            },
            bookings: {
              __args: { from: fromDate, to: toDate },
              ...basicIndicatorInfo,
            },
            channelDistribution: {
              __args: {
                from: fromDate,
                to: toDate,
                mergeAndAcqIncluded: MergeAndAcquisitionIncluded.all,
              },
              channelName: true,
              percentage: true,
              total: true,
            },
            repeats: {
              __args: {
                from: fromDate,
                to: toDate,
              },
            },
          },
        },
      },
    })
  },
  async getHomeComparativeIndicators({
    homeId,
    fromDate,
    toDate,
    granularity,
    pagination,
  }: HomeIndicatorParams) {
    return arriereBackoffice.queries.homes({
      __alias: 'getHomeComparativeIndicators',
      __args: { homeIds: [homeId], pagination },
      results: {
        indicators: {
          __args: { granularity },
          finance: {
            bookingVolume: {
              __args: {
                from: fromDate,
                to: toDate,
                mergeAndAcqIncluded: MergeAndAcquisitionIncluded.all,
              },
              ...indicatorInfo,
              annualizedValue: true,
            },
            realizedRevenue: {
              __args: {
                from: fromDate,
                to: toDate,
                mergeAndAcqIncluded: MergeAndAcquisitionIncluded.all,
              },
              ...indicatorInfo,
              annualizedValue: true,
            },
          },
          sales: {
            averageBookingLength: {
              __args: { from: fromDate, to: toDate },
              ...indicatorInfo,
            },
            medianBookingLeadTime: {
              __args: { from: fromDate, to: toDate },
              ...indicatorInfo,
            },
            occupancyRate: {
              __args: { from: fromDate, to: toDate },
              ...indicatorInfo,
            },
            weekendOccupancyRate: {
              __args: { from: fromDate, to: toDate },
              ...indicatorInfo,
            },
            averageGroupSize: {
              __args: { from: fromDate, to: toDate },
              ...basicIndicatorInfo,
            },
            cancelledBookings: {
              __args: { from: fromDate, to: toDate },
              ...basicIndicatorInfo,
            },
            returningGuests: {
              __args: { from: fromDate, to: toDate },
              ...basicIndicatorInfo,
            },
            bookings: {
              __args: { from: fromDate, to: toDate },
              ...basicIndicatorInfo,
            },
            channelDistribution: {
              __args: {
                from: fromDate,
                to: toDate,
                mergeAndAcqIncluded: MergeAndAcquisitionIncluded.all,
              },
              channelName: true,
              percentage: true,
              total: true,
            },
          },
        },
      },
    })
  },
  getFutureRevenueByHome({ from, to, homeId }: { from: IDate; to: IDate; homeId: string }) {
    return arriereBackoffice.queries.home({
      __alias: 'getFutureRevenueByHome',
      __args: { homeId },
      indicators: {
        __args: {
          granularity: Granularity.monthly,
        },
        finance: {
          futureRevenue: {
            __args: { from, to, mergeAndAcqIncluded: MergeAndAcquisitionIncluded.all },
            date: true,
            booked: true,
            paid: true,
          },
        },
      },
    })
  },

  async getPropertyBookSections(homeId: string, kind: BookKind) {
    return arriereBackoffice.queries.home({
      __alias: 'getPropertyBookSections',
      __args: { homeId },
      region: {
        id: true,
      },
      book: {
        __args: { kind },
        sections: {
          homeId: true,
          defaultValue: true,
          value: true,
          active: true,
          title: true,
          listOrder: true,
          key: true,
          bookKind: true,
        },
      },
    })
  },

  async getAllProperties(pagination: PaginationInput) {
    return arriereBackoffice.queries.homes({
      __alias: 'getAllProperties',
      __args: {
        pagination,
      },
      results: {
        id: true,
        name: true,
        region: {
          name: true,
          id: true,
        },
      },
    })
  },

  async getHomeNames(homeIds: UUID[]) {
    return arriereBackoffice.queries
      .homes({
        __alias: 'getHomeNames',
        __args: {
          pagination: {
            page: 1,
            size: homeIds.length,
          },
          homeIds,
        },
        results: {
          name: true,
          title: true,
        },
      })
      .then(({ results }) => results.map(home => home.title))
  },

  async getPropertiesFloorRates(homeIds: string[]) {
    return arriereBackoffice.queries.floorRates({
      __args: {
        homeIds,
      },
      homeId: true,
      rate: true,
      rateByMonth: true,
    })
  },
  async getAllPropertiesForTapeChart({
    acquisitionChannel,
    amenityIds,
    checkIn,
    checkOut,
    exactBedrooms,
    highlightIds,
    homeIds,
    licensingFramework,
    maxPrice,
    minBathrooms,
    minBedrooms,
    minPets,
    minPrice,
    name,
    pagination,
    petPolicy,
    regionIds,
    revenueModel,
    riskLevel,
    seasonGroupIds,
    targetPartner,
    withOffboarded,
    minOccupancyRate,
    maxOccupancyRate,
    occupancyPeriod,
  }: TapeChartPropertiesFilterProps) {
    return arriereBackoffice.queries.tapeChartPaginatedHomes.raw({
      __alias: 'tapeChartPaginatedHomes',
      __args: {
        regionIds,
        homeIds,
        name,
        checkIn,
        checkOut,
        minPrice,
        maxPrice,
        petPolicy,
        minBathrooms,
        minBedrooms,
        amenityIds,
        highlights: highlightIds,
        riskLevel,
        revenueModel,
        pagination,
        seasonGroupIds,
        licensingFramework,
        targetPartner,
        acquisitionChannel,
        minPets,
        bedrooms: exactBedrooms,
        withOffboarded,
        maxOccupancyRate,
        minOccupancyRate,
        occupancyPeriod,
        sortBy: HomeSorting.regionName,
      },
      pages: true,
      paginationParams: {
        page: true,
      },
      results: {
        isIntegratedPricingEnabled: true,
        id: true,
        name: true,
        region: {
          id: true,
          name: true,
        },
        guestReadinessStatus: true,
        lifecycle: {
          offboarded: true,
          riskLevel: true,
        },
        details: {
          maintenanceStatus: true,
          publishHighRisk: true,
          onBoardingStatus: true,
        },
        home: {
          hasPool: true,
          roomsCount: true,
          bedroomsCount: true,
        },
      },
    })
  },
  async propertyBlockedPeriods({
    from,
    to,
    homeIds,
  }: {
    from: IDate
    to: IDate
    homeIds: string[]
  }) {
    return arriereBackoffice.queries
      .tapeChart({
        __alias: 'getPropertyBlockedPeriods',
        __args: {
          homeIds,
          period: { start: from, end: to },
        },
        searchAvailabilityBlocks: {
          __args: {},
          bookingId: true,
          homeId: true,
          id: true,
          notificationSetting: {
            notifyAuthor: true,
            notifyRelocationTeam: true,
            notifyFieldOpsTeam: true,
          },
          parent: {
            id: true,
            source: true,
            homeId: true,
            bookingId: true,
            createdBy: true,
            createdAt: true,
            updatedAt: true,
            updatedBy: true,
            note: true,
            period: {
              start: true,
              end: true,
            },
          },
          period: {
            start: true,
            end: true,
          },
          source: true,
          createdBy: true,
          createdAt: true,
          updatedAt: true,
          updatedBy: true,
          note: true,
        },
      })
      .then(data => data.searchAvailabilityBlocks)
  },
  async propertyDatedPrices({ from, to, homeIds }: { from: IDate; to: IDate; homeIds: string[] }) {
    return arriereBackoffice.queries
      .tapeChart({
        __args: {
          period: { start: from, end: to },
          homeIds,
        },
        searchHomeDatedPrices: {
          date: true,
          homeId: true,
          maxStay: true,
          minStay: true,
          rate: true,
        },
      })
      .then(results => results.searchHomeDatedPrices)
  },
  async getPropertiesDatedPrices({
    from,
    to,
    homeIds,
    weekdays,
    rateType,
  }: {
    from: IDate
    to: IDate
    homeIds: string[]
    weekdays: number[]
    rateType: RateType
  }): Promise<CellData[]> {
    return arriereBackoffice.queries
      .pricingTapeChart({
        __args: {
          rateType,
        },
        searchHomeDatedPrices: {
          __args: { period: { start: from, end: to }, homeIds, dayOfWeek: weekdays },
          date: true,
          homeId: true,
          minStay: true,
          rate: true,
          checkinAllowed: true,
          checkoutAllowed: true,
          canEdit: true,
          hasHistory: true,
          rateType: true,
          occupancyType: true,
          hasConditionsHistory: true,
        },
      })
      .then(results => results.searchHomeDatedPrices)
  },

  async getPricingTapeChartCellHistory({
    date,
    homeId,
    rateType,
  }: {
    date: IDate
    homeId: string
    rateType: RateType
  }) {
    return arriereBackoffice.queries
      .pricingTapeChart({
        __args: { rateType },
        searchHomeDatedPriceChangeHistory: {
          __args: { homeId, date },
          homeId: true,
          date: true,
          previousValue: true,
          newValue: true,
          author: { kind: true, name: true },
          message: true,
          updatedAt: true,
          previousConditions: {
            minStay: true,
            maxStay: true,
            checkinAllowed: true,
            checkoutAllowed: true,
          },
          newConditions: {
            minStay: true,
            maxStay: true,
            checkinAllowed: true,
            checkoutAllowed: true,
          },
        },
      })
      .then(results => results.searchHomeDatedPriceChangeHistory)
  },

  async updatePropertiesDatedPricesAndRestrictions(
    values: PriceInput[],
    message: string,
    rateType: RateType,
  ): Promise<string | any> {
    return arriereBackoffice.mutations
      .pricingTapeChart({
        __args: {
          rateType,
        },
        updateDatedValues: {
          __args: {
            values,
            message,
          },
        },
      })
      .then(result => result?.updateDatedValues)
  },

  async unsetDatedValues(
    values: UnsetPriceInput[],
    message: string,
    rateType: RateType,
  ): Promise<string | undefined> {
    return arriereBackoffice.mutations
      .pricingTapeChart({
        __args: {
          rateType,
        },
        unsetDatedValues: {
          __args: {
            values,
            message,
          },
        },
      })
      .then(result => result?.unsetDatedValues)
  },

  async updateSeasonPricing(values: SeasonPriceInput[], message: string): Promise<string | any> {
    return arriereBackoffice.mutations
      .seasonPrices({
        update: {
          __args: {
            values,
            message,
          },
        },
      })
      .then(result => result?.update)
  },

  async getHomeInvoices(homeId: string): Promise<HomeInvoiceWithUrl[]> {
    return arriereBackoffice.queries.invoices({
      __args: {
        homeId,
      },
      info: {
        assetId: true,
        fileName: true,
        homeId: true,
        period: {
          start: true,
          end: true,
        },
        invoiceType: true,
        createdAt: true,
        updatedAt: true,
      },
      url: true,
    })
  },
  async getHighlightsList(): Promise<Pick<Highlight, 'id' | 'name'>[]> {
    return arriereBackoffice.queries.highlights({
      name: true,
      id: true,
    })
  },
  async getFilterableAmenitiesList(): Promise<Pick<Amenity, 'id' | 'name'>[]> {
    return arriereBackoffice.queries.filterableAmenities({
      name: true,
      id: true,
    })
  },
  async getPropertyReviews({
    homeId,
    page,
    sources,
    paginationSize,
    from,
    to,
    terms,
    minNps,
    maxNps,
    overallMaxRating,
    overallMinRating,
    categories,
  }: GetPropertyReviewsProps) {
    return arriereBackoffice.queries
      .home({
        __alias: 'getPropertyReviews',
        __args: {
          homeId,
        },
        reviewsByBookingSources: {
          __args: {
            pagination: { page, size: paginationSize },
            sources,
            period: { start: from, end: to },
            terms,
            minNps,
            maxNps,
            overallMinRating,
            overallMaxRating,
            categories,
          },
          total: true,
          pages: true,
          results: {
            bookingId: true,
            bookingHash: true,
            source: true,
            guestName: true,
            period: {
              start: true,
              end: true,
            },
            reviews: {
              id: true,
              source: true,
              privateFeedback: true,
              response: true,
              externalReviewId: true,
              createdAt: true,
              bookingPeriod: {
                start: true,
                end: true,
              },
              categoryReviews: {
                category: true,
                rating: true,
                comment: true,
              },
              booking: {
                guestId: true,
                guestName: true,
              },
            },
          },
        },
      })
      .then(results => results.reviewsByBookingSources)
  },
  async getPropertyRatings(homeId: string) {
    return arriereBackoffice.queries
      .home({
        __alias: 'getPropertyRatings',
        __args: {
          homeId,
        },
        ratings: {
          homeAway: {
            overall: {
              avgStars: true,
              totalRates: true,
            },
          },
          airBnb: {
            overall: {
              avgStars: true,
              totalRates: true,
            },
            accuracy: {
              avgStars: true,
              totalRates: true,
            },
            checkIn: {
              avgStars: true,
              totalRates: true,
            },
            communication: {
              avgStars: true,
              totalRates: true,
            },
            cleanliness: {
              avgStars: true,
              totalRates: true,
            },
            location: {
              avgStars: true,
              totalRates: true,
            },
            value: {
              avgStars: true,
              totalRates: true,
            },
          },
        },
      })
      .then(results => results.ratings)
  },

  async getHomesDistributions({ homeId }: HomesDistributionsProps) {
    return arriereBackoffice.queries.home({
      __alias: 'getHomesDistributions',
      __args: {
        homeId,
      },
      distributions: {
        __args: {
          activeOnly: false,
        },
        active: true,
        agencyAccount: {
          id: true,
          agency: true,
          market: true,
          notes: true,
          channel: true,
        },
        status: true,
        statuses: {
          category: true,
          status: {
            author: {
              name: true,
              kind: true,
            },
            startTime: true,
            updatedAt: true,
            endTime: true,
            errors: {
              code: true,
              internalCategory: true,
              reason: true,
              ts: true,
            },
            warnings: {
              code: true,
              internalCategory: true,
              reason: true,
              ts: true,
            },
            status: true,
          },
        },
        lastUpdateAt: true,
      },
      listings: {
        active: true,
        agencyAccount: {
          id: true,
          agency: true,
        },
      },
    })
  },

  async getHomesDistributionsAndListings({ homeId, ota }: HomesDistributionsProps) {
    return arriereBackoffice.queries.home({
      __alias: 'getHomesDistributionsAndListings',
      __args: {
        homeId,
      },
      distributions: {
        __args: {
          activeOnly: false,
          agency: ota,
        },
        active: true,
        agencyAccount: {
          agency: true,
          market: true,
          notes: true,
          channel: true,
        },
        status: true,
        statuses: {
          category: true,
          status: {
            author: {
              name: true,
              kind: true,
            },
            startTime: true,
            updatedAt: true,
            endTime: true,
            errors: {
              code: true,
              internalCategory: true,
              reason: true,
              ts: true,
            },
            warnings: {
              code: true,
              internalCategory: true,
              reason: true,
              ts: true,
            },
            status: true,
          },
        },
        lastUpdateAt: true,
      },
      listings: {
        __args: { agency: ota },
        active: true,
        agencyAccount: {
          agency: true,
        },
        propertyType: true,
        propertyTypeGroup: true,
      },
    })
  },
  async getHomeQualityStandardsState(homeId: string) {
    return arriereBackoffice.queries.airbnbDistribution
      .raw({
        __alias: 'getHomeQualityStandardsState',
        tripIssuesSearch: {
          __args: { homeIds: [homeId], regionIds: [] },
          total: true,
          pages: true,
          results: {
            qualityStandardsState: true,
            qualityStandardsStateDate: true,
            stats: {
              bookingIssuesCount: true,
              bookingIssuesRatio: true,
            },
          },
        },
      })
      .then(res => res.data.tripIssuesSearch.results[0])
  },
  async getHomesList(searchTerm: string) {
    return arriereBackoffice.queries.homes({
      __alias: 'getHomesList',
      __args: { pagination: { page: 1, size: 5000 }, name: searchTerm },
      results: {
        id: true,
        name: true,
        title: true,
        thumbnail: {
          url: true,
        },
        region: {
          name: true,
        },
        isAncestor: true,
        isSuccessor: true,
        revenueModel: true,
      },
    })
  },
  async getHomesListWithCalendar(from: IDate, to: IDate, name?: string) {
    return arriereBackoffice.queries.homes.raw({
      __alias: 'getHomesListWithCalendar',
      __args: { name },
      results: {
        id: true,
        name: true,
        title: true,
        thumbnail: {
          url: true,
        },
        region: {
          name: true,
        },
        maxOccupancy: true,
        isAncestor: true,
        isSuccessor: true,
        revenueModel: true,
        details: {
          onBoardingStatus: true,
        },
        lifecycle: {
          guestReadinessStatus: true,
        },
        sourceListed: {
          website: true,
        },
        calendar: {
          __args: {
            from,
            to,
          },
          date: true,
          status: true,
          rate: true,
          minStay: true,
          restrictions: {
            checkInAllowed: true,
            checkOutAllowed: true,
          },
        },
        bookings: {
          __args: {
            from,
            to,
            leadSources: [],
          },
          results: {
            id: true,
            reservationType: {
              name: true,
            },
            period: {
              start: true,
              end: true,
            },
            status: true,
          },
        },
      },
    })
  },

  async getHomeDistributionListingInfo({ homeId, ota }: HomesDistributionsProps) {
    if (homeId === undefined || ota === undefined) {
      return undefined
    }

    return arriereBackoffice.queries
      .distribution({
        __alias: 'getHomeDistributionListingInfo',
        getHomeListing: {
          __args: { homeId, agency: ota },
          homeId: true,
          source: true,
          title: true,
          externalHomeId: true,
          description: true,
          createdAt: true,
          updatedAt: true,
          access: true,
          houseRules: true,
          interaction: true,
          neighborhood: true,
          notes: true,
          space: true,
          transit: true,
          distributionSettings: {
            commonChannelSettings: {
              defaultPrice: true,
              weeklyDiscount: true,
              monthlyDiscount: true,
              latitude: true,
              longitude: true,
              hasInternet: true,
              hasWheelchairAccess: true,
              hasBedInCommonSpace: true,
              eventsAllowed: true,
            },
            airbnbSettings: {
              headline: true,
              propertyType: true,
              listingType: true,
              checkInOptionCategory: true,
              checkInOptionInstruction: true,
              synchronizationCategory: true,
              cancellationPolicy: true,
              cancellationNonRefundablePricePercent: true,
              earlyBirdDiscount: true,
              earlyBirdDiscountDays: true,
              earlyBirdDiscountPercent: true,
              lastMinuteBirdDiscount: true,
              lastMinuteBirdDiscountDays: true,
              lastMinuteBirdDiscountPercent: true,
              propertyTypeGroup: true,
              securityDeposit: true,
              permitId: true,
              leadTime: true,
              useUnitLeadTime: true,
              directions: true,
              roomNumber: true,
              manual: true,
            },
          },
          active: true,
          apiConnected: true,
          summary: true,
          channels: {
            active: true,
            ota: true,
          },
          agencyAccount: {
            agency: true,
            channel: true,
            channelAccountId: true,
            email: true,
            id: true,
            market: true,
            notes: true,
          },
        },
      })
      .then(result => {
        return result.getHomeListing
      })
  },

  // Mutations

  async updateCheckTime({ homeId, checkOutTime }: UpdateCheckTime) {
    return arriereBackoffice.mutations.home({
      updateCheckTime: {
        __args: {
          homeId,
          checkOutTime,
        },
      },
    })
  },

  async updatePropertyBookSections(sections: HomeBookSectionInput[]) {
    return arriereBackoffice.mutations.homeBooks({
      bindSections: { __args: { sections } },
    })
  },

  async createHomeImage({ homeId, extension, size, fileName }: CreateHomeImageProps) {
    return arriereBackoffice.mutations.createHomeImage({
      __args: {
        homeId,
        extension,
        size,
        fileName,
      },
      assetId: true,
      url: true,
      externalUrl: true,
    })
  },

  async updateHome({
    homeId,
    title,
    name,
    customFields,
    shortDescription,
    description,
    address,
    maxOccupancy,
    maxAdults,
    bathroomsCount,
    halfBathroomsCount,
    verifyCustomFields = false,
    verifyAllCustomFieldsUsed,
  }: IUpdateHomeParams) {
    return arriereBackoffice.mutations.updateHome({
      __args: {
        homeId,
        title,
        name,
        customFields,
        shortDescription,
        description,
        address,
        maxOccupancy,
        maxAdults,
        bathroomsCount,
        halfBathroomsCount,
        verifyCustomFields,
        verifyAllCustomFieldsUsed,
      },
    })
  },
  async addBuyouts({ homeId, buyoutIds }: IBuyOutsMutationParams) {
    return arriereBackoffice.mutations.addBuyouts({
      __args: {
        homeId,
        buyoutIds,
      },
    })
  },
  async deleteBuyouts({ homeId, buyoutIds }: IBuyOutsMutationParams) {
    return arriereBackoffice.mutations.deleteBuyouts({
      __args: {
        homeId,
        buyoutIds,
      },
    })
  },

  async createHomeInvoiceAsset({
    homeId,
    size,
    fileName,
  }: {
    homeId: string
    size?: number
    fileName?: string
  }): Promise<AssetUploadInfo> {
    return arriereBackoffice.mutations.createHomeInvoice({
      __args: {
        homeId,
        size,
        fileName,
      },
      assetId: true,
      url: true,
      externalUrl: true,
    })
  },

  async deleteHomeInvoice(assetId: string): Promise<boolean> {
    return arriereBackoffice.mutations.deleteHomeInvoice({
      __args: { assetId },
    })
  },

  async updateHomeInvoice({
    homeId,
    assetId,
    fileName,
    period,
    invoiceType,
  }: {
    homeId: string
    assetId: string
    fileName: string
    period: DateRangeInput
    invoiceType: HomeInvoiceType
  }): Promise<boolean> {
    return arriereBackoffice.mutations.updateHomeInvoice({
      __args: { homeId, assetId, fileName, period, invoiceType },
    })
  },
  async updateTaxesAndFees({
    homeId,
    taxesToAdd,
    taxesToRemove,
    taxesToUpdate,
  }: UpdateTaxesAndFeesMutation) {
    return arriereBackoffice.mutations.homeTaxMutations({
      updateHomeTaxesV2: {
        __args: {
          homeId,
          taxesToAdd,
          taxesToRemove,
          taxesToUpdate,
        },
      },
    })
  },

  async updateHomeListing({ agency, homeId, formFields }: UpdateHomeListingMutation) {
    return arriereBackoffice.mutations.distribution({
      updateHomeListing: {
        __args: {
          agency,
          homeId,
          ...formFields,
        },
      },
    })
  },

  // REVENUE CONFIGURATION

  async getPropertyRevenueConfiguration(id: string) {
    return arriereBackoffice.queries.revenueProperty({
      __args: { id },
      id: true,
      seasonGroup: {
        id: true,
        name: true,
      },
      yieldingGroup: {
        id: true,
        name: true,
      },
      yieldingMap: {
        id: true,
        name: true,
      },
      weeklyDiscount: true,
      monthlyDiscount: true,
      nightlyMinimumFloor: true,
      floorRateByMonth: true,
      integratedPricing: true,
      pricingSourceType: true,
    })
  },

  async setPropertyRevenueConfiguration({
    id,
    yieldingMapId,
    yieldingGroupId,
    seasonGroupId,
    weeklyDiscount,
    monthlyDiscount,
    message,
    nightlyMinimumFloor,
    floorRateByMonth,
    pricingSource,
  }: PropertyRevenueConfigurationMutation) {
    return arriereBackoffice.mutations
      .propertyMutations({
        update: {
          __args: {
            id,
            yieldingMapId,
            yieldingGroupId,
            seasonGroupId,
            weeklyDiscount,
            monthlyDiscount,
            message,
            nightlyMinimumFloor,
            floorRateByMonth,
            pricingSource,
          },
          id: true,
        },
      })
      .then(response => !!response?.update?.id)
  },

  async getRevenueConfigurationTableData(
    pagination?: PaginationInput,
    name?: string,
    regionIds?: string[],
  ) {
    return arriereBackoffice.queries
      .revenueProperties({
        __alias: 'revenuePropertiesConfigurationData',
        __args: { pagination, name, regionIds },
        total: true,
        paginationParams: { size: true, page: true },
        pages: true,
        results: {
          id: true,
          name: true,
          regionId: true,
          regionName: true,
          yieldingMap: { name: true, id: true },
          yieldingGroup: { name: true, id: true },
          seasonGroup: { name: true, id: true },
          weeklyDiscount: true,
          monthlyDiscount: true,
        },
      })
      .then(response => response?.results || [])
  },

  async updateLifecycle(lifecycleProps: UpdateLifecycle) {
    return arriereBackoffice.mutations.updateLifecycleFields({
      __args: lifecycleProps,
    })
  },

  async updateHomeCancellationPolicies({ homeId, policies }: UpdateCancellationPoliciesProps) {
    return arriereBackoffice.mutations.home({
      updateHomeCancellationPolicy: {
        __args: { homeId, policies },
      },
    })
  },

  async getPropertyRestrictionsById({ homeId, period }: PropertyRestrictionsInfoProps) {
    return arriereBackoffice.queries.home({
      __alias: 'getPropertyRestrictionsById',
      __args: { homeId },
      maxOccupancy: true,
      petPolicy: true,
      maxAdults: true,
      maxPets: true,
      calendar: {
        __args: { from: period.from, to: period.to },
        restrictions: {
          checkInAllowed: true,
          checkOutAllowed: true,
        },
      },
      customFields: {
        __args: {
          sectionIds: [REVENUE_CONFIGURATION_SECTION_ID],
        },
        fields: {
          id: true,
          name: true,
          value: true,
        },
      },
      region: {
        timezone: true,
      },
      checkTime: {
        checkIn: true,
      },
    })
  },

  async getPropertyMinStay(homeId: string, from: IDate, to: IDate) {
    return arriereBackoffice.queries.home.memo({
      __alias: 'getPropertyMinStayByDate',
      __args: { homeId },
      calendar: {
        __args: { from, to },
        date: true,
        minStay: true,
      },
    })
  },

  async calendarDatePricesAvailability({
    from,
    to,
    homeId,
  }: {
    from: IDate
    to: IDate
    homeId: string
  }) {
    return arriereBackoffice.queries.home
      .memo({
        __alias: 'getCalendarDatePricesAvailability',
        __args: {
          homeId,
        },
        calendar: {
          __args: { from, to },
          date: true,
          minStay: true,
          rate: true,
          status: true,
          restrictions: {
            checkInAllowed: true,
            checkOutAllowed: true,
          },
        },
      })
      .then(results => results.calendar)
  },

  async getOTATaxesAndFeesChangeLog({ homeId, taxId }: HomeTaxAgencyOverrideChangeLogProps) {
    return arriereBackoffice.queries
      .homeTaxAgencyOverrideChangeLog({
        __alias: 'getOTATaxesAndFeesChangeLog',
        __args: { homeId, taxId },
        homeId: true,
        taxId: true,
        agency: true,
        changes: {
          valueType: true,
          newValue: true,
          prevValue: true,
          createdAt: true,
          createdBy: { name: true, kind: true },
        },
      })
      .then(results => results)
  },

  async updatePropertyHighlights({ homeId, homeHighlights }: UpdatePropertyHighlightProps) {
    return arriereBackoffice.mutations.home({
      updateHomeHighlights: {
        __args: {
          homeId,
          homeHighlights,
        },
      },
    })
  },
  async getVendor(vendorId: string, homeId: string, availabilityRange: DateRangeInput) {
    return arriereBackoffice.queries
      .vendors({
        __alias: 'findVendor',
        findVendor: {
          __args: { vendorId },
          id: true,
          name: true,
          email: true,
          phone: true,
          contactPerson: true,
          vendorTypes: true,
          startDate: true,
          active: true,
          endDate: true,
          employmentClassification: true,
          notes: true,
          isRecurring: true,
          salesforceVendorId: true,
          activeVendorServices: {
            service: true,
            homeId: true,
            ratePeriod: true,
            rateInCents: true,
          },
          availabilities: {
            __args: { availabilityRange },
            date: true,
            times: {
              startTime: true,
              endTime: true,
            },
            kind: true,
          },
          activeVendorServicesRegionStats: {
            regionServices: {
              regionId: true,
              regionName: true,
              vendorHomes: {
                name: true,
                id: true,
              },
              regionHomesCount: true,
              vendorHomesToRegionHomesPercentage: true,
            },
          },
          ...(homeId
            ? {
                ownerPreferredExistsInServices: {
                  __args: { homeId },
                },
                ownerManagedExistsInServices: {
                  __args: { homeId },
                },
                billingFrequencyMostRecentInServices: {
                  __args: { homeId },
                },
              }
            : {}),
        },
      })
      .then(results => results?.findVendor)
  },
  async getPropertyServicesAndVendors(homeId: string) {
    return arriereBackoffice.queries
      .vendorServices({
        __alias: 'propertyVendorServices',
        propertyVendorServices: {
          __args: { homeId },
          services: {
            vendorId: true,
            isActive: true,
            service: true,
            serviceFrequency: true,
            frequencyInterval: true,
            weekDays: true,
            vendorRateId: true,
            rateInCents: true,
            ratePeriod: true,
            payingParty: true,
            notes: true,
            isHousekeeping: true,
            billingFrequency: true,
            regionId: true,
            homeId: true,
            vendor: { id: true, name: true },
            daysOfService: true,
          },
          area: true,
        },
      })
      .then(results => results.propertyVendorServices)
  },
  async updateFrequencyDetails(props: UpdateServiceFrequencyOnPropertyLevel) {
    return arriereBackoffice.mutations
      .vendorService({
        __alias: 'updateFrequencyDetails',
        updateFrequencyDetails: {
          __args: props,
        },
      })
      .then(response => response.updateFrequencyDetails)
  },
  async updateVendorRecurring(vendorId: string, isRecurring: boolean) {
    return arriereBackoffice.mutations
      .vendors({
        __alias: 'propertyVendorServices',
        updateVisitFrequency: {
          __args: { vendorId, isRecurring },
        },
      })
      .then(results => results.updateVisitFrequency)
  },
  async updateVendorStatus(vendorRateId: string, isActive: boolean) {
    return arriereBackoffice.mutations
      .vendorService({
        __alias: 'propertyVendorServices',
        updateVendorRateStatus: {
          __args: { vendorRateId, isActive },
        },
      })
      .then(results => results.updateVendorRateStatus)
  },
  async getVendorsFromActiveService() {
    return arriereBackoffice.queries
      .vendorServices({
        __alias: 'getVendorsFromActiveService',
        vendorsFromActiveServices: {
          id: true,
          name: true,
        },
      })
      .then(results =>
        results.vendorsFromActiveServices?.map(vendor => ({
          label: vendor.name,
          value: vendor.id,
        })),
      )
  },
  async getHomeServices(showOffboarded: boolean) {
    return arriereBackoffice.queries
      .vendorServices({
        __alias: 'searchHomeServices',
        searchHomeServices: {
          __args: { pagination: { size: 99999, page: 1 }, withOffboarded: showOffboarded }, // Without pagination
          results: {
            home: {
              id: true,
              name: true,
              region: {
                id: true,
                name: true,
              },
            },
            checkoutClean: {
              vendor: {
                id: true,
                name: true,
              },
              rateInCents: true,
            },
            checkoutCleanRatePerFoot: true,
            poolClean: {
              vendor: {
                id: true,
                name: true,
              },
              rateInCents: true,
              ratePeriod: true,
            },
            spa: {
              vendor: {
                id: true,
                name: true,
              },
              rateInCents: true,
              ratePeriod: true,
            },
            landscaping: {
              vendor: {
                id: true,
                name: true,
              },
              rateInCents: true,
              ratePeriod: true,
            },
            pestControl: {
              vendor: {
                id: true,
                name: true,
              },
              rateInCents: true,
              ratePeriod: true,
            },
          },
        },
      })
      .then(results => results?.searchHomeServices?.results)
  },
  async getHomeOptions(name: string, regionIds?: string[]) {
    return arriereBackoffice.queries.homes
      .raw({
        __alias: 'getHomeOptions',
        __args: { name, regionIds, pagination: { page: 1, size: 99999 } }, // Without pagination
        results: {
          id: true,
          name: true,
          title: true,
          isAncestor: true,
          region: {
            name: true,
          },
        },
      })
      .then(({ data }) => {
        return data.results
      })
      .catch(error => {
        Alert.Error((error as Error).message)
        return []
      })
  },
}

export const getPropertyReviewsOverview = async ({
  homeId,
  start,
  end,
}: PropertiesReviewsParams) => {
  return arriereBackoffice.queries.home
    .raw({
      __alias: 'getPropertyReviewsOverview',
      __args: {
        homeId,
      },
      ratings: {
        __args: { period: { start, end } },
        ...ratingsOverviewFragment,
      },
      indicators: {
        customerExperience: {
          npsInfo: {
            __args: { from: start, to: end },
            ...npsRatingsOverviewFragment,
          },
        },
      },
    })
    .then(({ data, errors }) => {
      if (errors?.length) {
        throw new Error(errors[errors?.length - 1]?.message)
      }

      return data
    })
}

export const getHomeCustomerExperienceIndicators = async ({
  from,
  homeId,
  to,
}: HomeCustomerExperienceIndicators) => {
  return arriereBackoffice.queries.home
    .raw({
      __alias: 'getHomeCustomerExperienceIndicators',
      __args: {
        homeId,
      },
      ratings: {
        __args: { period: { start: from, end: to } },
        airBnb: {
          overall: {
            avgStars: true,
          },
        },
      },
      indicators: {
        customerExperience: {
          nps: { __args: { from, to } },
          positiveReviews: { __args: { from, to, source: LeadSource.airBnb } },
        },
      },
    })
    .then(({ data, errors }) => {
      if (errors?.length) {
        throw new Error(errors.length > 1 ? errors[1]?.message : errors[0]?.message)
      }

      return {
        ...data.indicators.customerExperience,
        avgRating: data.ratings?.airBnb?.overall?.avgStars,
      }
    })
}

export const verifyPropertyInfo = ({ homeId, verifications }: VerifyPropertyInfoProps) => {
  return arriereBackoffice.mutations.home({
    verifyItems: {
      __args: { homeId, verifications },
    },
  })
}

export const updateHomeImages = async ({ homeId, images }: UpdateHomeImageProps) => {
  return arriereBackoffice.mutations.updateHomeImages({
    __alias: 'updateHomeImages',
    __args: {
      homeId,
      images,
    },
  })
}

export const getBookingsWithOtaIssues = (homeIds: string[]) => {
  return arriereBackoffice.queries.bookingsWithOtaIssues({
    __alias: 'getBookingsWithOtaIssues',
    __args: { homeIds },
    booking: {
      hash: true,
      cancellationInfo: {
        source: true,
        createDate: true,
      },
      guestName: true,
      guests: true,
      period: {
        end: true,
        start: true,
      },
      status: true,
      reviews: {
        categoryReviews: {
          category: true,
          rating: true,
          comment: true,
          tags: true,
        },
        privateFeedback: true,
        response: true,
      },
      id: true,
    },
    bookingChannelId: true,
    details: true,
    issues: {
      bookingChannelId: true,
      cancellation: true,
      csViolations: {
        category: true,
        tag: true,
      },
      ota: true,
      reviewIssues: {
        lowCategoryRatings: {
          category: true,
          rating: true,
        },
        lowOverallRating: true,
        negativeCategoryTags: {
          category: true,
          tag: true,
        },
        otaReviewId: true,
      },
    },
  })
}

export const syncAirbnbDistribution = ({
  homeId,
  syncAmenities,
  syncAvailability,
  syncFeesAndTaxes,
  syncListingContent,
  syncPeriod,
  syncPhotos,
  syncPricing,
  syncRooms,
}: SyncOtaDistributionMutation) => {
  return arriereBackoffice.mutations.distribution({
    __alias: 'syncAirbnbDistribution',
    asyncPushToAirbnb: {
      __args: {
        params: {
          homeIds: [homeId],
          period: syncPeriod,
          pushAmenities: syncAmenities,
          pushCalendar: syncAvailability,
          pushContent: syncListingContent,
          pushHomePhotos: syncPhotos,
          pushPricing: syncPricing,
          pushRooms: syncRooms,
          pushTaxFees: syncFeesAndTaxes,
        },
      },
      status: true,
    },
  })
}

export const syncRentalsUnitedDistribution = ({
  homeId,
  syncAmenities,
  syncAvailability,
  syncFeesAndTaxes,
  syncListingContent,
  syncPeriod,
  syncPhotos,
  syncPricing,
  syncRooms,
}: SyncOtaDistributionMutation) => {
  return arriereBackoffice.mutations.distribution({
    __alias: 'syncRentalsUnitedDistribution',
    asyncPushToRentalsUnited: {
      __args: {
        params: {
          homeIds: [homeId],
          period: syncPeriod,
          pushAmenities: syncAmenities,
          pushCalendar: syncAvailability,
          pushContent: syncListingContent,
          pushHomePhotos: syncPhotos,
          pushPricing: syncPricing,
          pushRooms: syncRooms,
          pushTaxFees: syncFeesAndTaxes,
        },
      },
      status: true,
    },
  })
}

export const syncVrboDistribution = ({
  homeId,
  syncAvailability,
  syncPricing,
}: SyncOtaDistributionMutation) => {
  return arriereBackoffice.mutations.distribution({
    __alias: 'syncVrboDistribution',
    asyncPushToVrbo: {
      __args: {
        params: {
          homeIds: [homeId],
          pushCalendar: syncAvailability,
          pushPricing: syncPricing,
        },
      },
      status: true,
    },
  })
}

export const getPropertyReviewsSourceStats = async ({
  homeId,
  page,
  paginationSize,
  from,
  to,
  terms,
  minNps,
  maxNps,
  overallMaxRating,
  overallMinRating,
  categories,
}: GetPropertyReviewsProps) => {
  return arriereBackoffice.queries
    .home({
      __alias: 'propertyReviewsSourceStats',
      __args: {
        homeId,
      },
      reviewsByBookingSources: {
        __args: {
          pagination: { page, size: paginationSize },
          period: { start: from, end: to },
          overallMaxRating,
          overallMinRating,
          terms,
          minNps,
          maxNps,
          categories,
        },
        sourceFacet: {
          source: true,
          count: true,
        },
        total: true,
      },
    })
    .then(results => results.reviewsByBookingSources)
}
