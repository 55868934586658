import React, { SVGProps } from 'react'

function _IconAvantstay(props: React.SVGProps<SVGSVGElement>, ref?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      width="442"
      height="44"
      viewBox="0 0 442 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M24.9922 24.5078L18.8789 9.65625L12.875 24.5078H24.9922ZM26.5977 28.4727H11.2695L5.58594 42.4648H0.921875L17.5391 1.83203H24.7773L41.4492 42.4648H32.332L26.5977 28.4727Z"
        fill="white"
      />
      <path
        d="M50.0625 1.83203H59.4414L72.4648 31.3672L85.4922 1.83203H90.2617L72.3047 42.4648H68.0703L50.0625 1.83203Z"
        fill="white"
      />
      <path
        d="M122.918 24.5078L116.809 9.65625L110.805 24.5078H122.918ZM124.527 28.4727H109.195L103.516 42.4648H98.8516L115.469 1.83203H122.703L139.375 42.4648H130.262L124.527 28.4727Z"
        fill="white"
      />
      <path
        d="M187.598 29.332V1.83203H191.941V42.4648H187.598L161.438 14.4297V42.4648H157.098V1.83203H161.438L187.598 29.332Z"
        fill="white"
      />
      <path
        d="M224.391 5.79688H209.863V1.83203H247.492V5.79688H232.965V42.4648H224.391V5.79688Z"
        fill="white"
      />
      <path
        d="M265.609 41.4453L263.414 27.9922H265.824L270.219 36.6719C271.559 39.3008 274.297 39.7305 277.297 39.7305C281.422 39.7305 284.801 37.6406 284.801 33.6719C284.801 29.707 282.066 27.668 276.922 25.9531L273.973 24.9883C267.273 22.7891 263.68 18.6641 263.68 12.4453C263.68 4.56641 271.402 0.707031 279.496 0.707031C282.441 0.707031 285.66 1.08203 288.23 1.99219L290.59 14.75H288.176L283.621 6.17188C282.711 4.45703 280.461 4.13672 278.207 4.13672C274.402 4.13672 271.184 6.12109 271.184 9.87109C271.184 12.5 272.418 15.0703 276.383 16.3594L279.332 17.3242C287.105 19.8438 292.52 23.0039 292.52 30.6719C292.52 38.6562 284.535 43.1602 276.867 43.1602C272.688 43.1602 269.203 42.7305 265.609 41.4453Z"
        fill="white"
      />
      <path
        d="M322.008 5.79688H307.48V1.83203H345.113V5.79688H330.586V42.4648H322.008V5.79688Z"
        fill="white"
      />
      <path
        d="M376.957 24.5078L370.848 9.65625L364.844 24.5078H376.957ZM378.562 28.4727H363.234L357.551 42.4648H352.891L369.508 1.83203H376.742L393.414 42.4648H384.301L378.562 28.4727Z"
        fill="white"
      />
      <path
        d="M425.453 42.4648H416.875V24.1289L400.422 1.83203H410.496L423.469 19.5195L436.656 1.83203H441.91L425.453 24.1836V42.4648Z"
        fill="white"
      />
    </svg>
  )
}

export const IconAvantstay = React.memo(React.forwardRef(_IconAvantstay))
