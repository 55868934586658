// @ts-nocheck
import React, { FC, PropsWithChildren, ReactNode } from 'react'

import { Tooltip } from '../../components/Tooltip'
import { capitalizeAll } from '../../utils/format'
import { injectVariables } from '../../utils/injectVariables'
import * as S from './IndicatorsTooltip.styles'
import { IndicatorDocProps } from './IndicatorsTooltip.types'

interface IndicatorsTooltipProps {
  title?: ReactNode
  documentationKey?: string
  extraText?: string
  variables?: { [key: string]: any }
  labelWidth?: number
  indicatorDocs?: IndicatorDocProps
}

// This Max length is based on min-width set on AutoSizeGrid
const MAX_TITLE_NAME_LENGTH = 64

export const IndicatorsTooltip: FC<PropsWithChildren<IndicatorsTooltipProps>> = ({
  title,
  children,
  documentationKey,
  extraText,
  variables = {},
  labelWidth,
  indicatorDocs,
}) => {
  const renderWithSimpleTooltip = () => (
    <Tooltip
      content={typeof title === 'string' ? capitalizeAll(title) : title}
      placement="top"
      disabled={labelWidth ? labelWidth > MAX_TITLE_NAME_LENGTH : true}
    >
      <span data-testid="tooltip">{children}</span>
    </Tooltip>
  )

  if (!documentationKey || !indicatorDocs) return renderWithSimpleTooltip()

  if (indicatorDocs[documentationKey]) {
    return (
      <Tooltip
        interactive
        placement="bottom"
        content={
          <S.TooltipContainer>
            {title && <S.TooltipTitle>{title}</S.TooltipTitle>}
            <S.TooltipSectionTitle>Definition</S.TooltipSectionTitle>
            <S.Markdown
              children={injectVariables(indicatorDocs[documentationKey].definition, { variables })}
            />
            <S.TooltipSectionTitle>Scope</S.TooltipSectionTitle>
            <S.Markdown
              children={injectVariables(indicatorDocs[documentationKey].scope, {
                variables,
              })}
            />
            <br />
            <S.Markdown children={extraText} />
          </S.TooltipContainer>
        }
      >
        <span data-testid="tooltip">{children}</span>
      </Tooltip>
    )
  }

  return renderWithSimpleTooltip()
}
