import differenceInDays from 'date-fns/differenceInDays'
import React, { FC } from 'react'

import { LeadSource } from '@avantstay/arriere-clients/dist/arriereBackoffice'
import { formatDateRange } from '@avantstay/format-date-range'

import { badgeColorByLeadSource, leadSourceLabel } from '../../constants'
import { formatDate, parseISODate, pluralize } from '../../utils'
import { BadgeColors, BadgeColorsProps } from '../Badge'
import { Review } from './components/Review'
import * as S from './ReviewTimeline.styles'
import { ReviewTimelineProps } from './ReviewTimeline.types'

const updatedLeadSourceLabel: Record<string, string> = {
  ...leadSourceLabel,
  [LeadSource.website]: 'AvantStay',
  [LeadSource.mobile]: 'AvantStay',
  [LeadSource.voyage]: 'AvantStay',
  [LeadSource.lighthouse]: 'AvantStay',
}

const updatedBadgeColorByLeadSource: Record<string, BadgeColorsProps> = {
  ...badgeColorByLeadSource,
  [LeadSource.website]: BadgeColors.channelMobile,
  [LeadSource.mobile]: BadgeColors.channelMobile,
  [LeadSource.voyage]: BadgeColors.channelMobile,
  [LeadSource.lighthouse]: BadgeColors.channelMobile,
}

export const ReviewTimeline: FC<ReviewTimelineProps> = ({
  bookingURL,
  guestName,
  isLastReviewOnPage,
  onlyReviewSummary,
  period,
  reviews,
  shouldDisplayHomeName,
  source,
}) => {
  const numberOfNights = differenceInDays(parseISODate(period.end), parseISODate(period.start))

  return (
    <S.ReviewTimelineContainer>
      <S.TimeLine />
      <S.ReviewHeader>
        <S.BadgeStyled badgeColor={updatedBadgeColorByLeadSource[source]}>
          {updatedLeadSourceLabel[source]}
        </S.BadgeStyled>

        <S.CalendarIconContainer>
          <S.IconCalendarStyled />
        </S.CalendarIconContainer>
        <S.BookingInfo>
          <S.GuestName>{guestName}</S.GuestName>
          {bookingURL ? (
            <S.DefaultLink href={bookingURL} target="_blank">
              {formatDateRange(parseISODate(period.start), parseISODate(period.end))} &bull;{' '}
              {numberOfNights} {pluralize(numberOfNights, 'nights', 'night')}
            </S.DefaultLink>
          ) : (
            <S.GuestName>
              {formatDateRange(parseISODate(period.start), parseISODate(period.end))} &bull;{' '}
              {numberOfNights} {pluralize(numberOfNights, 'nights', 'night')}
            </S.GuestName>
          )}
          {shouldDisplayHomeName && reviews.length > 0 ? (
            <S.GuestName>{reviews[0].home?.name}</S.GuestName>
          ) : null}
        </S.BookingInfo>
      </S.ReviewHeader>

      {reviews.map((review, index) => (
        <S.ReviewContainer key={`reviewTime-${review.id}`}>
          <S.CreatedAtDate>
            {/* Required to keep UTC for consistency with overview stats that are calculated in UTC */}
            {formatDate(new Date(review.createdAt).toUTCString(), 'MMM dd, yyyy hh:mm a')}
          </S.CreatedAtDate>
          <S.TimelineDot />
          <Review
            key={review.id}
            isLastReview={reviews.length - 1 === index}
            isLastReviewOnPage={isLastReviewOnPage}
            review={review}
            hideComments={onlyReviewSummary}
          />
        </S.ReviewContainer>
      ))}
    </S.ReviewTimelineContainer>
  )
}
