import 'mobx-react/batchingForReactDom'
import * as React from 'react'
import { createRoot } from 'react-dom/client'

import arriereBackoffice from '@avantstay/arriere-clients/dist/arriereBackoffice'
import arriereLighthouse from '@avantstay/arriere-clients/dist/arriereLighthouse'
import arrierePublic from '@avantstay/arriere-clients/dist/arrierePublic'

import { RoutingStore } from '@stores/RoutingStore'
import '@utils/validateExtends'

import MainRouter from './MainRouter'

RoutingStore.setup()

if (process.env.REACT_APP_LIVE_API_MOCKING === 'true') {
  import('@/__mocks__').then(({ setupMocks }) => setupMocks())
}

// eslint-disable-next-line camelcase
window._fs_ready = () => {
  if (!window.FS) return

  const session = window.FS.getCurrentSession()
  const sessionUrl = window.FS.getCurrentSessionURL()

  const apis = [arriereBackoffice, arriereLighthouse, arrierePublic]

  apis.forEach(api => {
    api.setHeader('X-Fullstory-Session-ID', session)
    api.setHeader('X-Fullstory-Session-URL', sessionUrl)
  })
}

const root = createRoot(document.getElementById('root') as HTMLElement)
root.render(<MainRouter />)

export { useGlobalStyle } from '@new/__global/hooks/useGlobalStyle'
