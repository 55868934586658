import { get } from 'lodash'
import React, { ComponentType, FC, PropsWithChildren, useContext } from 'react'

import AuthContext from '@contexts/Auth/AuthContext'
import { createInstance, enums, OptimizelyFeature, OptimizelyProvider } from '@optimizely/react-sdk'

const optimizely = createInstance({
  sdkKey: process.env.REACT_APP_TABLEAU_OPTIMIZELY_SDK_KEY,
  logLevel: enums.LOG_LEVEL.ERROR,
  datafileOptions: {
    autoUpdate: true,
    updateInterval: 20000,
    urlTemplate: 'https://optimizely.avantstay.com/datafiles/%s.json',
  },
})

interface ToggleFeatureProps {
  feature: Features | undefined
  DisabledComponent?: ComponentType
  shouldRenderIfFeatureIsNotFound?: boolean
}

export enum Features {
  AddCoupon = 'add_coupon',
  BookingsExportCSV = 'bookings_export_csv',
  Quotes = 'quotes',
  Coupons = 'list_coupons',
  Regions = 'regions',
  PropertyAmenities = 'property_amenities',
  CustomerExperienceDashboardIndicators = 'customer_experience_dashboard_indicators',
  Refunds = 'refunds',
  ValueAddedServicesRequests = 'value_added_services',
  DirectPaymentsTransactionScreen = 'direct_payments_transaction_screen',
  FutureRevenue = 'future_revenue',
  RecentBookingVolumeSection = 'recent_booking_volume_section',
  ChannelsDistributionSection = 'channels_distribution_section',
  OverviewNpsSection = 'overview_nps_section',
  RevenueIndicatorsSection = 'revenue_indicators_section',
  HomesCountingSection = 'homes_counting_section',
  OverviewIndicatorsChartsSection = 'overview_indicators_charts_section',
  TapeChartFloatActions = 'tape_chart_float_actions',
  People = 'people',
  Guests = 'guests',
  Owners = 'owners',
  Operations = 'operations',
  TaxesAndFees = 'taxes_and_fees',
  RevenueSettings = 'revenue_settings',
  PropertyPricingCalendar = 'property_pricing_calendar',
  PropertyRevenueConfiguration = 'property_revenue_configuration',
  CustomerExperienceOverviewSection = 'customer_experience_overview_section',
  PropertiesBookingVolumeFilter = 'properties_booking_volume_filter',
  PropertiesInHouseMonitoringFilter = 'properties_in_house_monitoring_filter',
  UsersAndPermissionsSettings = 'users_and_permissions_settings',
  SeasonGroups = 'season_groups',
  Seasons = 'seasons',
  YieldMaps = 'yield_maps',
  YieldGroups = 'yield_groups',
  Discounts = 'discounts',
  Markups = 'markups',
  AirbnbSuperhostReport = 'airbnb_superhost_report',
  BookingVolumeMandAData = 'booking_volume_m_and_a_data',
  HomesCountingSectionLiveUnits = 'homes_counting_section_live_units',
  HomesCountingSectionSignedUnits = 'homes_counting_section_signed_units',
  HomesCountingSectionNew = 'homes_counting_section_new',
  HomesCountingSectionOnBoarding = 'homes_counting_section_onboarding',
  HomesCountingSectionPrelisted = 'homes_counting_section_prelisted',
  HomesCountingSectionListed = 'homes_counting_section_listed',
  HomesCountingSectionOnBoarded = 'homes_counting_section_onboarded',
  HomesCountingSectionPipeline = 'homes_counting_section_pipeline',
  HomesCountingSectionRamping = 'homes_counting_section_ramping',
  HomesCountingSectionStabilized = 'homes_counting_section_stabilized',
  CustomerExperience = 'customer_experience',
  AirbnbExperience = 'airbnb_experience',
  VrboExperience = 'vrbo_experience',
  SuperhostExperience = 'superhost_experience',
  OwnerIncomeDashboard = 'owner_income_dashboard',
  NPSExperience = 'nps_experience',
  RatingsProperties = 'ratings_properties',
  HomeownersNPS = 'homeowners_nps',
}

export const FeatureToggleProvider: FC<PropsWithChildren> = ({ children }) => {
  const { user } = useContext(AuthContext)
  const userId = get(user, 'id', 'unknown')

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: OptimizelyProvider should allow children props
    <OptimizelyProvider
      optimizely={optimizely}
      timeout={500}
      user={{
        id: userId,
        attributes: {
          name: get(user, 'name', 'unknown'),
        },
      }}
    >
      {children}
    </OptimizelyProvider>
  )
}

export const ToggleFeature: FC<PropsWithChildren<ToggleFeatureProps>> = ({
  feature,
  DisabledComponent = () => null,
  children,
  shouldRenderIfFeatureIsNotFound = true,
}) => {
  const enabledFeatures = optimizely.getEnabledFeatures()
  const noneFeatureAvailable = enabledFeatures.length === 0

  // 'noneFeatureAvailable' is used here to workaround adblocker blockage
  // to optimizely requests, this changes the behavior to allow access to a
  // a feature when no optimizely info is available
  if (!feature || noneFeatureAvailable) {
    return shouldRenderIfFeatureIsNotFound ? <>{children}</> : null
  }

  return (
    <OptimizelyFeature feature={feature} autoUpdate>
      {isEnabled => (isEnabled ? children : DisabledComponent && <DisabledComponent />)}
    </OptimizelyFeature>
  )
}

export const getEconLastClosedPeriod = () =>
  optimizely.getFeatureVariableString('econ_last_closed_period', 'econLastClosedPeriod')
