export const IS_PRODUCTION = /avantstay\.com/i.test(window.location.host)
export const IS_LOCALHOST = /localhost/i.test(window.location.host)

export const CUSTOM_FRIDGE_STOCKING_PACKAGE_ID = 'b4bd9fe6-0032-11ec-9f06-9def1ccde851'

export const INTERNAL_SECTION_HEADER_HEIGHT = 107
export const INTERNAL_SECTION_HEADER_CONTENT_HEIGHT = 80

export const CODES_REVEALED_LOCAL_STORAGE_KEY = 'codes-revealed'
export const CODE_REVEAL_TIMEOUT_IN_MINUTES = 10

export const TRAVEL_PARTNERS_RESERVATION_ID = 'ad361cc2-38af-11ef-a7c3-e15a57156cdf'
