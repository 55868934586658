import React, { FC } from 'react'

import { ProgressBar } from '../../components/ProgressBar'

import * as S from './RatingBar.styles'
import { getNPSRatingColor, getPercentageOf, getRatingColor } from './RatingBar.utils'

export interface RatingBarProps {
  isNPS?: boolean
  isOverall?: boolean
  rating?: number
  shouldDisplayTwoDecimals?: boolean
  shouldHideProgressBar?: boolean
  type?: 'default' | 'points'
}

interface GetFormattedRatingProps {
  rating?: number
  shouldDisplayTwoDecimals?: boolean
}

const getFormattedRating = ({ rating, shouldDisplayTwoDecimals }: GetFormattedRatingProps) => {
  if (rating != null || rating === 0) {
    return shouldDisplayTwoDecimals ? rating?.toFixed(2) : rating?.toString()
  }
}

const MAX_OVERALL_RATING = 5
const MAX_NPS_RATING = 10

export const RatingBar: FC<RatingBarProps> = ({
  isNPS,
  isOverall = false,
  rating,
  shouldDisplayTwoDecimals,
  shouldHideProgressBar,
  type = 'default',
}) => {
  const maxRatingValue = isNPS ? MAX_NPS_RATING : MAX_OVERALL_RATING

  const formattedRating = getFormattedRating({ rating, shouldDisplayTwoDecimals })

  const ratingNumber = Number(formattedRating)
  
  const progressBarColor = isNPS ? getNPSRatingColor(ratingNumber) : getRatingColor(ratingNumber)

  const ratingToDisplay = rating !== undefined ? formattedRating : 'N/A'

  if (shouldHideProgressBar) {
    return (
      <S.RatingValue color={progressBarColor} $isMediumSize>
        {ratingToDisplay}
      </S.RatingValue>
    )
  }

  return (
    <S.RatingBar>
      <ProgressBar
        color={progressBarColor}
        percentage={rating ? getPercentageOf(rating, maxRatingValue) : 0}
        type={type}
      />
      <S.RatingValue $isMediumSize={isOverall} color={progressBarColor}>
        {ratingToDisplay}
      </S.RatingValue>
    </S.RatingBar>
  )
}
