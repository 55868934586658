import * as pathToRegexp from 'path-to-regexp'
import { FC } from 'react'
import { FileText, Home, IconProps, PieChart, Settings, Share2, Tool } from 'react-feather'

import { IconRating } from '@avantstay/backoffice-vectors'

import { IconFinance, IconInsights, IconIot, IconUser } from '@new/__ui/__vectors'

import { IS_PRODUCTION } from '@utils/constants'
import { Features } from '@utils/optimizely'

interface RouteItem {
  exact?: boolean
  feature?: Features
  icon?: FC<IconProps>
  navHeader?: boolean
  path: string
  title: string
}

const internalRoutes = {
  ui: { path: '/ui', exact: true, title: 'ui' },
  dashboard: {
    path: '/dashboard',
    navHeader: true,
    title: 'Dashboard',
    icon: PieChart,
  },
  regions: {
    path: '/markets',
    exact: false,
    // navHeader: true,
    title: 'Markets',
    feature: Features.Regions,
    // icon: Globe,
  },
  regionsFinance: {
    path: '/markets/finance',
    exact: true,
    navHeader: false,
    title: 'Markets',
    feature: Features.Regions,
  },
  regionsRatings: {
    path: '/markets/ratings',
    exact: true,
    navHeader: false,
    title: 'Ratings',
    feature: Features.Regions,
  },
  regionInfo: {
    path: '/markets/:id/info',
    exact: true,
    navHeader: false,
    title: 'Market Info',
  },
  properties: {
    path: '/properties/:viewMode?',
    exact: true,
    navHeader: true,
    title: 'Properties',
    icon: Home,
  },
  property: { path: '/properties/:id', exact: false, title: 'Property' },
  propertyStats: {
    path: '/properties/:id/stats',
    exact: true,
    title: 'Property Stats',
  },
  propertyEntryInformation: {
    path: '/properties/:id/entry-information',
    exact: true,
    title: 'Property Entry Information',
  },
  inHouseMonitoring: {
    path: '/properties/:id/in-house-monitoring',
    navHeader: false,
    title: 'In-House Monitoring',
    exact: true,
  },
  propertyHomeownerInfo: {
    path: '/properties/:id/homeowner-info/:section?/:viewMode?',
    navHeader: false,
    title: 'Owner Documents',
    exact: true,
  },
  propertyInfo: {
    path: '/properties/:id/info/:section?/:viewMode?',
    navHeader: false,
    title: 'Property Info',
    exact: true,
  },
  propertyFinances: {
    path: '/properties/:id/finances',
    navHeader: false,
    title: 'Property Finances',
    exact: true,
  },
  propertyPhotoGallery: {
    path: '/properties/:id/photo-gallery',
    navHeader: false,
    title: 'Property Photo Gallery',
    exact: true,
  },
  propertyManual: {
    path: '/properties/:id/manual/:section?',
    navHeader: false,
    title: 'Property Manuals',
    exact: true,
  },
  propertyValueAddedServices: {
    path: '/properties/:id/value-added-services',
    navHeader: false,
    title: 'Property Value-Added Services',
    exact: true,
  },
  propertyWorkOrderTemplatesHistory: {
    path: '/properties/:id/work-order-templates/:template/history',
    navHeader: false,
    title: 'Property Work Order Templates - Changes History',
    exact: true,
  },
  propertyWorkOrderTemplates: {
    path: '/properties/:id/work-order-templates/:template?/:section?/:item?',
    navHeader: false,
    title: 'Property Work Order Templates',
    exact: true,
  },
  propertyThingsToDo: {
    path: '/properties/:id/things-to-do/:section?',
    navHeader: false,
    title: 'Things To Do',
    exact: true,
  },
  propertyServicesAndVendors: {
    path: '/properties/:id/services-and-vendors',
    navHeader: false,
    title: 'Services & Vendors',
    exact: true,
  },
  login: { path: '/login', title: 'Login' },
  home: { path: '/', title: 'home' },
  bookings: {
    path: '/bookings/all',
    exact: true,
    navHeader: false,
    title: 'Bookings',
  },
  fastTapeChart: {
    path: '/bookings/tapechart',
    exact: true,
    navHeader: true,
    title: 'Bookings',
    icon: FileText,
  },
  operations: {
    path: '/operations/:feature?/:id?',
    exact: false,
    navHeader: true,
    title: 'Operations',
    icon: Tool,
    feature: Features.Operations,
  },
  operationCases: {
    path: '/operations/cases/:id?',
    exact: true,
    navHeader: false,
    title: 'Operation Cases',
  },
  operationOpenCase: {
    path: '/operations/cases?case=:id?',
    exact: true,
    navHeader: false,
    title: 'Operation Open Case',
  },
  sfdcWorkOrder: {
    path: '/operations',
    exact: true,
    navHeader: false,
    title: 'Operation Details',
  },
  operationWorkOrder: {
    path: '/operations/work-order',
    exact: true,
    navHeader: false,
    title: 'Operation Details',
  },
  operationInfo: {
    path: '/operations/work-order/:id/info',
    exact: true,
    navHeader: false,
    title: 'Operation Basic Info',
  },
  operationIssues: {
    path: '/operations/work-order/:id/issues',
    exact: true,
    navHeader: false,
    title: 'Operation Issues',
  },
  operationReview: {
    path: '/operations/work-order/:id/review',
    exact: true,
    navHeader: false,
    title: 'Operation Review',
  },
  operationMarkets: {
    path: '/operations/markets/:id?',
    exact: true,
    navHeader: false,
    title: 'Operation Markets',
  },
  operationVendors: {
    path: '/operations/vendors',
    exact: true,
    navHeader: false,
    title: 'Operation Vendors',
  },
  operationRecurringServices: {
    path: '/operations/recurring-cases',
    exact: true,
    navHeader: false,
    title: 'Operation Recurring Cases',
  },
  operationRecurringExpenses: {
    path: '/operations/routine-and-mt-expenses',
    exact: true,
    navHeader: false,
    title: 'Operation Recurring Expenses',
  },
  operationBookingBasedCase: {
    path: '/operations/booking-based-case',
    exact: true,
    navHeader: false,
    title: 'Operation Booking Based Case',
  },
  people: {
    path: '/people',
    title: 'People',
    navHeader: true,
    exact: false,
    icon: IconUser,
    feature: Features.People,
  },
  servicesRequests: {
    path: '/bookings/requests/services',
    title: 'Service Requests',
    navHeader: false,
    exact: true,
    feature: Features.ValueAddedServicesRequests,
  },
  refundRequests: {
    path: '/bookings/requests/refunds',
    title: 'Refunds Requests',
    exact: true,
    navHeader: false,
  },
  guests: {
    path: '/people/guests/:id?',
    title: 'Guests',
    navHeader: false,
    exact: false,
    feature: Features.Guests,
  },
  owners: {
    path: '/people/owners/:id?',
    title: 'Owners',
    navHeader: false,
    exact: false,
    feature: Features.Owners,
  },
  bookingLeads: {
    path: '/people/booking-leads/:id?',
    title: 'Booking Leads',
    navHeader: false,
    exact: false,
  },
  revenueMapping: {
    path: '/revenue/revenue-mapping',
    title: 'Revenue',
    exact: true,
    icon: IconFinance,
    feature: Features.TaxesAndFees,
  },
  finance: {
    path: '/revenue',
    navHeader: true,
    title: 'Revenue',
    exact: true,
    icon: IconFinance,
    feature: Features.TaxesAndFees,
  },
  fees: {
    path: '/revenue/fees',
    navHeader: false,
    title: 'Fees',
    exact: true,
    feature: Features.TaxesAndFees,
  },
  ownerIncomeDashboard: {
    path: '/revenue/owner-income-dashboard',
    title: 'Owner Income Dashboard',
    exact: true,
    navHeader: false,
    Features: Features.OwnerIncomeDashboard,
  },
  revenueSettings: {
    path: '/revenue/settings',
    navHeader: false,
    title: 'Revenue Settings',
    exact: false,
    feature: Features.RevenueSettings,
  },
  pricingTapeChart: {
    path: '/revenue/pricing-tape-chart',
    navHeader: false,
    title: 'Pricing Tape Chart',
    exact: true,
    feature: Features.TaxesAndFees,
  },
  revenueBookingsTapeChart: {
    path: '/revenue/revenue-tape-chart',
    navHeader: false,
    title: 'Revenue Tape Chart',
    exact: true,
    feature: Features.TaxesAndFees,
  },
  seasons: {
    path: '/revenue/settings/seasons',
    navHeader: false,
    title: 'Seasons',
    exact: true,
    feature: Features.Seasons,
  },
  seasonGroups: {
    path: '/revenue/settings/seasons-groups',
    navHeader: false,
    title: 'Season Groups',
    exact: true,
    feature: Features.SeasonGroups,
  },
  yieldMaps: {
    path: '/revenue/settings/yield-maps',
    navHeader: false,
    title: 'Yield Maps',
    exact: true,
    feature: Features.YieldMaps,
  },
  yieldGroups: {
    path: '/revenue/settings/yield-groups',
    navHeader: false,
    title: 'Yield Groups',
    exact: true,
    feature: Features.YieldGroups,
  },
  discounts: {
    path: '/revenue/settings/discounts',
    navHeader: false,
    title: 'Long Term Discounts',
    exact: true,
    feature: Features.Discounts,
  },
  markups: {
    path: '/revenue/settings/markups',
    navHeader: false,
    title: 'Markups',
    exact: true,
    feature: Features.Markups,
  },
  ratings: {
    path: '/ratings',
    title: 'Ratings',
    navHeader: true,
    icon: IconRating,
    exact: false,
  },
  ratingsSuperhost: {
    path: '/ratings/superhost',
    exact: true,
    navHeader: false,
    title: 'Superhost',
  },
  ratingsPerformance: {
    path: '/ratings/performance',
    exact: true,
    navHeader: false,
    title: 'Ratings',
  },
  ratingsMarketsReview: {
    path: '/ratings/reviews/:id',
    exact: true,
    navHeader: false,
    title: 'Review Markets',
  },
  accountingResolutionCenter: {
    path: '/revenue/accounting-resolution-center',
    exact: false,
    navHeader: false,
    title: 'Accounting Resolution Center',
  },
  bookingConflicts: {
    path: '/bookings/booking-conflicts',
    exact: true,
    navHeader: false,
    title: 'Booking Conflicts',
  },
  idVerification: {
    path: '/bookings/id-verification',
    title: 'ID Verification',
    navHeader: false,
    exact: true,
  },
  tapeChart: {
    path: '/bookings/tapechart-legacy',
    title: 'Tape Chart (Legacy)',
    exact: true,
    navHeader: false,
  },
  marketing: {
    path: '/marketing',
    title: 'Marketing',
    navHeader: true,
    exact: false,
    icon: Share2,
  },
  reports: {
    path: '/reports',
    title: 'Reports',
    exact: false,
    icon: IconInsights,
  },
  settings: {
    path: '/settings',
    title: 'Settings',
    navHeader: true,
    exact: false,
    icon: Settings,
  },
  coupons: {
    path: '/marketing/coupons',
    title: 'Coupons',
    navHeader: false,
    exact: true,
    feature: Features.Coupons,
  },
  addCoupon: {
    path: '/marketing/coupons/add',
    title: 'Add coupon',
    navHeader: false,
    exact: true,
  },
  editCoupon: {
    path: '/marketing/coupons/edit/:code',
    title: 'Edit coupon',
    navHeader: false,
    exact: true,
  },
  customFields: {
    path: '/settings/custom-fields',
    title: 'Custom Fields',
    exact: true,
    navHeader: false,
  },
  sharedManuals: {
    path: '/settings/property-manuals',
    title: 'Property Manuals',
    exact: true,
    navHeader: false,
  },
  globalWorkOrderTemplatesPermissions: {
    path: '/settings/work-order-templates/permissions',
    title: 'Work Order Templates - Permissions',
    exact: true,
    navHeader: false,
  },
  globalWorkOrderTemplatesHistory: {
    path: '/settings/work-order-templates/history/:template?',
    title: 'Work Order Templates - Changes History',
    exact: true,
    navHeader: false,
  },
  globalWorkOrderTemplates: {
    path: '/settings/work-order-templates/:template?/:section?/:item?',
    title: 'Work Order Templates',
    exact: true,
    navHeader: false,
  },
  sharedThingsToDo: {
    path: '/settings/things-to-do',
    title: 'Things To Do',
    exact: true,
    navHeader: false,
  },
  userManagement: {
    path: '/settings/user-management',
    title: 'User management',
    exact: true,
    navHeader: false,
  },
  usersAndPermissions: {
    path: '/settings/users-and-permissions/:section?',
    title: 'Users & Permissions',
    exact: true,
    navHeader: false,
    feature: Features.UsersAndPermissionsSettings,
  },
  blacklistedEmails: {
    path: '/settings/blacklisted-emails',
    title: 'Blacklisted Emails',
    exact: true,
    navHeader: false,
  },
  blacklistEmail: {
    path: '/settings/add-email-to-blacklist',
    title: 'Add email to blacklist',
    exact: true,
    navHeader: false,
  },
  otaAmenitiesMapping: {
    path: '/settings/ota-amenities-mapping',
    title: 'OTA Amenities Mapping',
    exact: true,
    navHeader: false,
  },
  otaTaxesAndFeesMapping: {
    path: '/settings/ota-taxes-fees-mapping',
    title: 'OTA Taxes and Fees Mapping',
    exact: true,
    navHeader: false,
  },
  addUser: {
    path: '/settings/user-management/add',
    title: 'Add user',
    exact: true,
    navHeader: false,
  },
  iotDevices: {
    path: '/settings/iot-devices',
    title: 'Iot Devices',
    exact: true,
    navHeader: false,
    icon: IconIot,
  },
  booking: {
    path: '/bookings/:hash',
    title: 'Booking',
    exact: false,
    navHeader: false,
  },
  bookingInfo: {
    path: '/bookings/:hash/info/:section?/:viewMode?',
    title: 'Booking Info',
    exact: true,
    navHeader: false,
  },
  bookingPaymentAndServices: {
    path: '/bookings/:hash/payment-and-services',
    title: 'Booking Payment And Services',
    exact: true,
    navHeader: false,
  },
  bookingGuests: {
    path: '/bookings/:hash/guests',
    title: 'Guests',
    exact: true,
    navHeader: false,
  },
  bookingCases: {
    path: '/bookings/:hash/cases',
    title: 'Cases',
    exact: true,
    navHeader: false,
  },
  bookingGuestDamage: {
    path: '/bookings/:hash/guest-damage/:section?/:viewMode?',
    title: 'Guest Damage',
    exact: true,
    navHeader: false,
  },
  bookingAgreements: {
    path: '/bookings/:hash/agreements',
    title: 'Agreements',
    exact: true,
    navHeader: false,
  },
  bookingPaymentDetails: {
    path: '/bookings/:hash/payment-details/:section?/:viewMode?',
    title: 'Booking Payment Details',
    exact: true,
    navHeader: false,
  },
  bookingValueAddedServices: {
    path: '/bookings/:hash/value-added-services',
    title: 'Booking Value Added Services',
    exact: true,
    navHeader: false,
  },
  bookingAccountingTimeline: {
    path: '/bookings/:hash/accounting-timeline/:section?',
    title: 'Booking Accounting Timeline',
    exact: true,
    navHeader: false,
  },
  bookingReviews: {
    path: '/bookings/:hash/reviews',
    exact: true,
    navHeader: false,
    title: 'Reviews',
  },
  bookingMessages: {
    path: '/bookings/:hash/messages/:selectedMessageId?',
    exact: true,
    navHeader: false,
    title: 'Messages',
  },
  bookingSMSConversations: {
    path: '/bookings/:hash/in-stay-conversation',
    exact: true,
    navHeader: false,
    title: 'In-stay Conversation',
  },
  bookingHistory: {
    path: '/bookings/:hash/history',
    exact: true,
    navHeader: false,
    title: 'History',
  },
  bookingUserSessions: {
    path: '/bookings/:hash/user-sessions',
    title: 'Guest Damage',
    exact: true,
    navHeader: false,
  },
  error: {
    path: '/error',
    exact: true,
    navHeader: false,
    title: 'Error',
  },
  propertyDashboard: {
    path: '/properties/:id/dashboard',
    navHeader: false,
    title: 'Property Dashboard',
    exact: true,
  },
  propertyRooms: {
    path: '/properties/:id/rooms',
    navHeader: false,
    title: 'Property Room Details',
    exact: true,
  },
  propertyLayout: {
    path: '/properties/:id/layout/:section?/:viewMode?',
    navHeader: false,
    title: 'Property Layout',
    exact: true,
  },
  propertyInternalConfiguration: {
    path: '/properties/:id/internal-configuration/:section?/:viewMode?',
    exact: true,
    navHeader: false,
    title: 'Internal Configuration',
  },
  timeline: {
    path: '/properties/:id/timeline',
    exact: true,
    navHeader: false,
    title: 'Property Timeline',
  },
  propertyReviews: {
    path: '/properties/:id/reviews',
    exact: true,
    navHeader: false,
    title: 'Reviews',
  },
  propertyTaxesAndFees: {
    path: '/properties/:id/taxes-and-fees',
    exact: true,
    navHeader: false,
    title: 'Taxes and Fees',
    feature: Features.TaxesAndFees,
  },
  propertyAmenities: {
    path: '/properties/:id/amenities/:section?',
    exact: true,
    navHeader: false,
    title: 'Amenities',
    feature: Features.PropertyAmenities,
  },
  propertyDistributionChannels: {
    path: '/properties/:id/distribution/:section?/:viewMode?',
    exact: true,
    navHeader: false,
    title: 'Listings & Distribution',
    feature: Features.PropertyAmenities,
  },
  propertyPricingCalendar: {
    path: '/properties/:id/pricing-calendar',
    exact: true,
    navHeader: false,
    title: 'Pricing Calendar',
    feature: Features.PropertyPricingCalendar,
  },
  propertyRevenueConfiguration: {
    path: '/properties/:id/revenue-configuration',
    exact: true,
    navHeader: false,
    title: 'Revenue Configuration',
    feature: Features.PropertyRevenueConfiguration,
  },
  propertyVendorRate: {
    path: '/properties/vendor_rates',
    exact: true,
    navHeader: false,
    title: 'Vendor Rates',
  },
  systemBehavior: {
    path: '/settings/system-behavior',
    title: 'System Behavior',
    exact: true,
    navHeader: false,
  },
  quotes: {
    path: '/marketing/quotes',
    title: 'Quotes',
    navHeader: false,
    exact: true,
    feature: Features.Quotes,
  },
  quotesHistory: {
    path: '/marketing/quotes/history',
    title: 'Quotes History',
    navHeader: false,
    exact: true,
    feature: Features.Quotes,
  },
  termsAndConditions: {
    path: '/settings/terms-conditions',
    title: 'Terms & Conditions',
    exact: true,
    navHeader: false,
  },
  termsAndConditionsDiff: {
    path: '/settings/terms-conditions/diff/:version1?/:version2?',
    title: 'Terms & Conditions Difference',
    exact: true,
    navHeader: false,
  },
  termsAndConditionsPreview: {
    path: '/settings/terms-conditions/preview/:versionId?',
    title: 'Terms & Conditions Preview',
    exact: true,
    navHeader: false,
  },
  hotels: {
    path: '/hotels',
    exact: true,
    navHeader: false,
    title: 'Hotels',
    icon: Home,
  },
  hotel: { path: '/hotels/:id', exact: false, title: 'Hotel' },
  hotelInfo: {
    path: '/hotels/:id/info/:section?/:viewMode?',
    navHeader: false,
    title: 'Hotel Info',
    exact: true,
  },
  hotelLayout: {
    path: '/hotels/:id/layout/:section?/:viewMode?',
    navHeader: false,
    title: 'Hotel Layout',
    exact: true,
  },
  hotelPhotoGallery: {
    path: '/hotels/:id/photo-gallery',
    navHeader: false,
    title: 'Hotel Photo Gallery',
    exact: true,
  },
  cancelScheduledSms: {
    path: '/scheduledSms/cancel',
    title: 'Cancel Scheduled SMS',
    navHeader: false,
    exact: true,
  },
}

export const ROUTES: Record<keyof typeof internalRoutes, RouteItem> = internalRoutes

export const mountRouteUrl = (
  path: string,
  params?: Record<string, unknown>,
  queries?: Record<string, string>,
) => {
  const toPath = pathToRegexp.compile(path)

  if (queries) {
    const parsedQueries = queries && new URLSearchParams(queries).toString()

    return `${toPath(params)}?${parsedQueries}`
  }

  return toPath(params)
}

export function openLink(link: string, target = '_blank') {
  window.open(link, target, 'noopener')
}

export const getMainMenuItems = (user: any) =>
  Object.keys(ROUTES)
    .filter(it => ROUTES[it as keyof typeof ROUTES].navHeader)
    .filter(it => (it === 'jobs' ? user?.permissions?.jobsMonitoring : true))

export const createLink = (linkTitle: string, params: string) => ({
  title: linkTitle,
  url: `http${IS_PRODUCTION ? 's' : ''}://${window.location.host}${params}`,
})
