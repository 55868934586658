import styled from 'styled-components'

import { ImgLite } from '@avantstay/avantstay-ui'
import {
  ExtensionProps,
  FilePreviewType,
  FileUploadStatus,
  FileViewMode,
  FileWrapperProps,
  ImageContainerProps,
} from '../../UploadArea.types'
import {
  _2XS_Bold,
  _S_Medium,
  _XS_Bold,
  mainColors,
  medias,
  semanticColors,
} from '../../../../styles'
import { getFileSizeByType } from '../../UploadArea.utils'
import { rgba } from '../../../../utils'

const DOC_HEIGHT_DESKTOP = '450px'
const DOC_HEIGHT_MOBILE = '225px'

// View Mode Container
export const Container = styled.div<FileWrapperProps>`
  position: relative;

  ${({ $viewMode }) =>
    $viewMode && $viewMode === FileViewMode.list
      ? `
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    max-width: 700px;


    ${medias.MD_SCREEN`
      flex-direction: row;
    `}
  `
      : `
    margin-right: 10px;
  `};

  ${({ $filePreviewType, $viewMode }) =>
    $viewMode === FileViewMode.list &&
    $filePreviewType === FilePreviewType.photoGallery &&
    `
    margin-bottom: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid ${mainColors.neutralMedium};
  `}
`

// File Preview
export const DocumentPreviewContainer = styled.div`
  width: 100%;
  top: 0;
  position: relative;
`

export const FilePreviewContainer = styled.div<FileWrapperProps>`
  position: relative;
  min-width: ${({ $filePreviewType, $viewMode }) =>
    getFileSizeByType($filePreviewType, $viewMode).width}px;
  height: ${({ $filePreviewType, $viewMode }) =>
    getFileSizeByType($filePreviewType, $viewMode).height}px;

  cursor: pointer;

  ${({ $filePreviewType }) =>
    $filePreviewType === FilePreviewType.photoGallery &&
    `
    padding-top: 30px;
  `}
`

export const Image = styled(ImgLite)<{ $hasFlatBorder?: boolean }>`
  width: 100%;
  height: 100%;
  border-radius: 4px;

  ${({ $hasFlatBorder }) =>
    $hasFlatBorder &&
    `
    border-radius: 0 0 4px 4px;
  `}
`

export const DocumentPreview = styled(Image)`
  height: ${DOC_HEIGHT_MOBILE};
  max-height: ${DOC_HEIGHT_MOBILE};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  ${medias.MD_SCREEN`
    min-height: ${DOC_HEIGHT_DESKTOP};
  `}
`

export const Cover = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 96px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${_S_Medium};
  color: ${mainColors.white};
  padding: 2px 9px;
  background: ${mainColors.primaryMedium};
  border-radius: 2px 2px 0 0;
  transform: translate(-50%, 0);
`

export const MakeAsCoverButton = styled(Cover)`
  display: none;
  border: 1px solid ${mainColors.white};
  border-bottom: 0;
`

const getImagePlaceHolderBorder = ({ error, $isCover, warning }: ImageContainerProps) => {
  const resetImageBorderRadius = `${Image} {
    border-radius: 0;
  }`

  if (error) {
    return `
      border: 2px solid ${semanticColors.failureMedium};
      ${resetImageBorderRadius};
    `
  }

  if ($isCover) {
    return `
      border: 2px solid ${mainColors.primaryMedium};
      ${resetImageBorderRadius};
    `
  }

  if (warning) {
    return `
      border: 2px solid ${semanticColors.warningDark};
      ${resetImageBorderRadius};
    `
  }

  return ''
}

export const ImagePlaceholder = styled.div<ImageContainerProps>`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 4px;

  ${({ $hasFlatBorder }) =>
    $hasFlatBorder &&
    `
    border-radius: 0 0 4px 4px;
  `};

  ${({ error, $isCover, warning }) => getImagePlaceHolderBorder({ error, $isCover, warning })};

  &:hover {
    ${MakeAsCoverButton} {
      display: flex;
    }
  }
`

export const ImageOverlay = styled.div<FileUploadStatus>`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 2px;

  ${({ warning }) =>
    warning &&
    `
    background: ${rgba(semanticColors.warningDark, 0.4)};
  `}

  ${({ error }) =>
    error &&
    `
    background: ${rgba(semanticColors.failureMedium, 0.4)};
  `}
`

export const DocumentPlaceholder = styled(ImagePlaceholder)`
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${mainColors.neutralLight};
  border-radius: 2px;
  height: ${DOC_HEIGHT_MOBILE};

  ${medias.MD_SCREEN`
    height: ${DOC_HEIGHT_DESKTOP};
  `}
`

export const FileContainer = styled(ImagePlaceholder)`
  position: relative;
  margin-bottom: 6px;
  border: 2px solid ${mainColors.neutralLight};
  border-radius: 2px;
  height: 104px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 42px;
  }
`

export const Extension = styled.div<ExtensionProps>`
  ${_XS_Bold};
  padding: 2px 7px;
  border-radius: 1px;
  position: absolute;
  background: ${({ background }) => background ?? mainColors.accentMedium};
  color: ${({ color }) => color ?? mainColors.white};
  left: 16px;
  top: 42px;
  text-transform: uppercase;
`

export const Error = styled.p`
  ${_2XS_Bold};
  color: ${mainColors.white};
`
