import React, { forwardRef, Ref, SVGProps } from 'react'

const _IconVrbo = (props: SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}
    >
      <g clipPath="url(#clip0_38059_8831)">
        <path
          d="M8.56056 11.3757L8.94975 10.6392L7.63084 4.57552C7.26327 2.86865 7.70435 2.12433 9.19192 1.86713V1.84375H7.81678C7.41894 2.02301 6.5757 2.56079 7.04705 4.57162L8.56056 11.3757Z"
          fill="currentColor"
        />
        <path
          d="M7.78216 12.841L8.16703 12.1083L6.4373 4.53655C6.11297 3.06739 6.25135 2.30359 6.79189 1.86713V1.84375H5.85784C5.57243 2.39322 5.48595 2.94269 5.8146 4.39626L7.78216 12.841ZM6.98649 14.3023L7.37135 13.5775L5.20919 4.37677C4.82865 2.94659 4.91514 2.2919 5.07081 1.84375H4.24487C4.23624 2.61758 4.34685 3.38874 4.57351 4.13516L6.98649 14.3023ZM3.48811 1.84375H2.51514V1.86713C2.94757 2.34256 3.0773 2.92321 3.42324 4.20531L6.01784 15.0505H6.60162L4.00703 4.17023C3.78287 3.40473 3.60959 2.62785 3.48811 1.84375ZM1.54216 1.84375H0.5V1.86713C1.45135 2.20617 1.91838 2.85696 2.29459 4.38067L4.85892 15.0466H5.4427L2.86108 4.31442C2.41568 2.45168 1.94865 2.10874 1.54216 1.84375ZM14.8005 1.84375C13.5724 2.49064 12.8416 3.68701 11.3108 6.65259L11.3497 6.67208C13.1184 3.68701 14.1259 2.49844 16.4092 1.87882V1.84375H14.8005ZM11.2243 6.59414L11.2632 6.61752C12.7162 3.53503 13.0968 2.6738 13.6892 1.86713V1.84375H12.513V1.87493C12.6341 3.11026 12.1757 4.24817 11.2243 6.59414Z"
          fill="currentColor"
        />
        <path
          d="M11.1463 6.54737L11.1853 6.57076C12.1063 3.80782 12.4047 2.58807 11.5917 1.84375H10.4458V1.86713C11.7431 2.28021 12.0112 3.46099 11.1463 6.54737Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_38059_8831">
          <rect width="16" height="16" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export const IconVrbo = forwardRef(_IconVrbo)
