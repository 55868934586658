const hasFileExt = (fileName: string): boolean => {
  const allowedExts = ['.pdf', '.jpg', '.jpeg', '.png', '.txt', '.xls', '.csv']
  return allowedExts.map(ext => fileName.includes(ext)).filter(has => has).length > 0
}

export const downloadFile = (uri: string, fileName: string) => {
  fetch(uri)
    .then(response => response.blob())
    .then(blob => {
      const newUrl = URL.createObjectURL(blob)
      const link = document.createElement('a')
      const fileNameWithExt = hasFileExt(fileName) ? fileName : `${fileName}.pdf`

      link.target = '_blank'
      link.href = newUrl
      link.download = fileNameWithExt

      document.body.appendChild(link)
      link.onclick = function onClick(e) {
        e.stopPropagation()
      }
      link.click()
      document.body.removeChild(link)
    })
}
