import React from 'react'

import { RatingBar, RatingBarProps } from '../RatingBar'
import * as S from './DotRatings.styles'

export interface StatProps extends Partial<RatingBarProps> {
  category: string
  rating: number
}

export const DotRatings = ({
  category,
  rating,
  shouldHideProgressBar = false,
  ...props
}: StatProps) => {
  return (
    <S.Stat>
      <S.Label>{category}</S.Label>
      <RatingBar
        rating={rating}
        type="points"
        shouldHideProgressBar={shouldHideProgressBar}
        {...props}
      />
    </S.Stat>
  )
}
