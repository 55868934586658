import React, { ReactNode } from 'react'

export type MenuPlacement = 'bottom' | 'top'
export type MenuPosition = 'absolute' | 'fixed' | 'sticky'

export enum Size {
  Default = 'default',
  Small = 'small',
  Big = 'big',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type OptionProps<OptionValueType = string, DataValueType = any> = {
  value: OptionValueType
  label: ReactNode
  customInputLabel?: ReactNode
  subtitle?: string
  dot?: string | null
  thumbnail?: string
  data?: DataValueType
  isDisabled?: boolean
}

export interface SelectFieldIssuesProps {
  error?: string
  warning?: string
}

export interface ControlCustomProps extends SelectFieldIssuesProps {
  isDisabled?: boolean
  isMulti?: boolean
  isFocused?: boolean
  removeBorder?: boolean
  removeBackground?: boolean
}

export type ValueType<OptionValueType> =
  | MultiSelectFieldStyledProps<OptionValueType>['value']
  | SingleSelectFieldStyledProps<OptionValueType>['value']

export type OptionsType<OptionValueType> =
  | OptionProps<OptionValueType>[]
  | OptionsGroup<OptionValueType>[]

export interface SingleSelectFieldStyledProps<OptionValueType> extends SelectFieldIssuesProps {
  isMulti?: false | undefined
  value?: OptionProps<OptionValueType> | null
  defaultValue?: OptionProps<OptionValueType> | null
  onChange?: (option: OptionProps<OptionValueType>) => void
}

export interface MultiSelectFieldStyledProps<OptionValueType> extends SelectFieldIssuesProps {
  isMulti: true
  value?: OptionProps<OptionValueType>[] | null
  defaultValue?: OptionProps<OptionValueType>[] | null
  onChange?: (options: OptionProps<OptionValueType>[]) => void
}

export type SelectFieldStyledProps<OptionValueType> =
  | SingleSelectFieldStyledProps<OptionValueType>
  | MultiSelectFieldStyledProps<OptionValueType>

export type GetMessageProps = { inputValue: string }
export type GetMessageType = ReactNode | ((props: GetMessageProps) => ReactNode)

export type BasicSelectFieldProps<OptionValueType> = SelectFieldStyledProps<OptionValueType> & {
  className?: string
  dataCy?: string
  dropdownIcon?: React.ReactNode
  filterOption?: (option: OptionProps<OptionValueType>, rawInput: string) => boolean
  hasDot?: boolean
  id?: string
  inputValue?: string
  isClearable?: boolean
  isDisabled?: boolean
  isSearchable?: boolean
  loadingMessage?: GetMessageType
  menuIsOpen?: boolean
  menuPlacement?: MenuPlacement
  menuPosition?: MenuPosition
  noOptionsMessage?: GetMessageType
  onBlur?: () => void
  onClick?: () => void
  onFocus?: () => void
  onInputChange?: (newValue: string) => void
  onMenuClose?: () => void
  onMenuOpen?: () => void
  placeholder?: string
  removeBackground?: boolean
  removeBorder?: boolean
  size?: Size
  skipOptionsFiltering?: boolean
  title?: ReactNode
  fullLength?: boolean
  hideValuesFromInput?: boolean
}

export type SelectFieldProps<OptionValueType> = BasicSelectFieldProps<OptionValueType> &
  StaticOptionsProps<OptionValueType>

export type AsyncSelectFieldProps<OptionValueType> = BasicSelectFieldProps<OptionValueType> &
  AsyncProps<OptionValueType>

export interface AsyncProps<OptionValueType> {
  loadOptions: (
    inputValue: string,
  ) => Promise<OptionProps<OptionValueType>[] | OptionsGroup<OptionValueType>[]>
}

export interface OptionsGroup<OptionValueType> {
  label: ReactNode
  options: OptionProps<OptionValueType>[]
}

export interface StaticOptionsProps<OptionValueType> {
  options: OptionsType<OptionValueType>
  isLoading?: boolean
}

export interface SelectFieldOptionProps {
  size?: Size
  isDisabled?: boolean
  isFocused?: boolean
  isSelected?: boolean
  key?: number
}

export interface SelectIndicatorProps extends SelectFieldIssuesProps {
  isMulti?: boolean
  hasValue?: boolean
  size?: Size
}
