import React from 'react'

const _IconExpedia = (props: React.SVGProps<SVGSVGElement>, ref?: React.Ref<SVGSVGElement>) => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    ref={ref}
  >
    <g clipPath="url(#clip0_38059_14580)">
      <path
        d="M9.45483 10.1201L9.14654 10.9927L6.53156 14.4876L7.00134 14.6041L11.5101 10.7064L14.1636 11.4652C14.4756 11.546 14.8298 11.5634 15.0995 11.5616C14.2655 13.3599 12.7396 14.8362 10.7247 15.5538C6.74168 16.9732 2.36228 14.8922 0.945608 10.9074C0.507026 9.67511 0.402427 8.4034 0.586852 7.19409L6.94354 9.4026L9.45483 10.1201Z"
        fill="currentColor"
      />
      <path
        d="M15.3791 10.8729C15.146 10.7215 14.8038 10.5233 14.4643 10.4141L11.8053 9.65533L10.0308 3.95927L9.57017 3.80971L9.94452 8.15975L9.73532 9.06352L7.2433 8.35243L0.657227 6.8018C1.17839 4.26665 2.97309 2.05906 5.58898 1.12684C9.57292 -0.292586 13.9505 1.7893 15.3672 5.77141C15.9782 7.48811 15.9388 9.27914 15.3791 10.8729Z"
        fill="currentColor"
      />
      <path
        d="M9.73474 9.06307L9.94486 8.16022L9.56959 3.81018L10.0302 3.95974L11.8047 9.65488L14.4637 10.4137C14.8032 10.5229 15.1455 10.7229 15.3785 10.8725C15.2968 11.1064 15.2033 11.3367 15.0996 11.5615C14.8298 11.5634 14.4757 11.545 14.1637 11.4661L11.5102 10.7055L7.00049 14.605L6.53163 14.4875L9.14569 10.9926L9.4549 10.121L6.94268 9.40164L0.586914 7.19497C0.6071 7.06284 0.630038 6.93164 0.656647 6.80135L7.24363 8.35198L9.73474 9.06307Z"
        fill="#E9EEF1"
      />
    </g>
    <defs>
      <clipPath id="clip0_38059_14580">
        <rect width="16" height="16" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
)

export const IconExpedia = React.memo(React.forwardRef(_IconExpedia))
